import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';

import { ReactComponent as Right } from '../../assets/svg/arrow-right.svg';
import { ReactComponent as Left } from '../../assets/svg/arrow-left.svg';

const Arrow = ({ className, dir, onClick }) => {
  return (
    <Wrapper onClick={onClick} className={className}>
      {dir == 'right' && <Right />}
      {dir == 'left' && <Left />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 29px;
  height: 29px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 50px;
  opacity: 1;
  position: relative;
  cursor: pointer;

  &::before,
  &:after {
    display: none;
  }

  svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    path {
      fill: ${Colors.turquoise};
    }
  }
`;

export default Arrow;
