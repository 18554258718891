import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import H6 from '../text-elements/heading/H6';
import Devices from '../../consts/Devices';

const ShareSheet = ({ url, close }) => {
  return (
    <Wrapper active={url !== null}>
      <Inner>
        <Icons>
          <CopyToClipboard text={window.location.href}>
            <Icon>
              <i className="fa fa-copy"></i>
            </Icon>
          </CopyToClipboard>
          <a href={`sms:?&body=${url}`} target="_blank">
            <Icon>
              <i className="fa fa-comment"></i>
            </Icon>
          </a>
          <a href={`mailto:?body=${url}`} target="_blank">
            <Icon>
              <i className="fa fa-envelope"></i>
            </Icon>
          </a>
          <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`} target="_blank">
            <Icon>
              <i className="fa fa-linkedin"></i>
            </Icon>
          </a>
        </Icons>
      </Inner>
      <Cancel onClick={close}>
        <H6>CANCEL</H6>
      </Cancel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 10px;
  width: 100%;
  width: calc(100% - 20px);
  z-index: 5;
  left: 10px;
  will-change: transform;
  transform: ${props =>
    props.active ? `translateY(0%) translateX(-50%)` : `translateY(150%) translateX(-50%)`};
  transition: transform 0.2s ease-out;
  max-width: 500px;
  left: 50%;

  @media ${Devices.laptop} {
    bottom: 40px;
  }
`;

const Inner = styled.div`
  background: ${Colors.white};
  box-shadow: ${Colors.boxShadow};
  border-radius: 15px;
  margin-bottom: 1rem;
`;

const Cancel = styled.div`
  background: ${Colors.white};
  box-shadow: ${Colors.boxShadow};
  height: 55px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  h6 {
    text-align: center;
    color: ${Colors.turquoise};
  }
`;

const Icons = styled.div`
  display: flex;
  padding: 2rem;
  justify-content: space-around;
`;

const Icon = styled.div`
  padding: 1rem;
  box-shadow: ${Colors.boxShadow};
  border-radius: 15px;
  background: ${Colors.turquoise};
  cursor: pointer;

  i {
    font-size: 22px;
    color: ${Colors.white};
  }
`;

export default ShareSheet;
