import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Typography from '../../../consts/Typography';
import Utils from '../../../consts/Utils';
import Devices from '../../../consts/Devices';

const B2 = ({ children, style, className }) => {
  return (
    <BodyText style={style} className={className}>
      {children}
    </BodyText>
  );
};

const BodyText = styled.span`
  ${Typography.montserratRegular};
  font-size: ${Typography.fontSizes.mobile.b2};
  line-height: ${Typography.lineHeights.mobile.b2};
  @media ${Devices.mobileL} {
    font-size: ${Typography.fontSizes.desktop.b2};
    line-height: ${Typography.lineHeights.desktop.b2};
  }
`;

export default B2;
