import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppActions from '../../actions/AppActions';
import ChecklistActions from '../../actions/ChecklistActions';
import SectionCarousel from '../../components/carousels/section-carousel/SectionCarousel';
import StylePrefabs from '../../consts/StylePrefabs';
import SaveChecklistPrompt from '../../components/save-checklist-prompt/SaveChecklistPrompt';
import Colors from '../../consts/Colors';
import Devices from '../../consts/Devices';

class Section extends React.Component {
  constructor(props) {
    super(props);

    this.findLatestQuestionIndex = this.findLatestQuestionIndex.bind(this);
  }

  componentDidMount() {
    this.props.setApp({
      showBlobs: false,
      showBackButton: true,
      backButton: {
        path: `/checklist/saved/${this.props.checklist.id}`,
        text: `${this.props.checklist.name} summary`
      }
    });
  }

  findLatestQuestionIndex(sectionIndex) {
    let latestQuestionIndex;

    let section = this.props.checklist.sections[sectionIndex];

    if (section.completed === true) {
      latestQuestionIndex = section.questions.length - 1;
      return latestQuestionIndex;
    }

    for (let question of section.questions) {
      if (!question.answered) {
        latestQuestionIndex = section.questions.indexOf(question);
        return latestQuestionIndex;
      }
    }
  }

  render() {
    let currentSectionIndex = parseInt(this.props.match.params.sectionId);
    let latestQuestionIndex = this.findLatestQuestionIndex(currentSectionIndex);

    return (
      <Wrapper>
        <SectionCarousel
          currentSectionIndex={currentSectionIndex}
          latestQuestionIndex={latestQuestionIndex}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.fullHeightMobileWithHeader}
  ${StylePrefabs.pageWrapper}
  max-width: 2500px !important;
  border-radius: 10px;
  overflow: hidden;
  @media ${Devices.laptop} {
    ${StylePrefabs.fullHeightDesktop};
  }
  /* @media (max-height: 760px) {
    min-height: 760px !important;
  } */
`;

const mapStateToProps = state => {
  return {
    checklist: state.checklist
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setApp: data => {
      dispatch(AppActions.setApp(data));
    },
    updateChecklist: data => {
      dispatch(ChecklistActions.updateChecklist(data));
    }
  };
};

const connectedSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(Section);

export default withRouter(connectedSection);
