import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import H5 from "../../text-elements/heading/H5";
import { ReactComponent as ArrowRight } from "../../../assets/svg/arrow-right.svg";
import Colors from "../../../consts/Colors";

const Basic = ({ onClick, children, disabled }) => {
  return (
    <Control disabled={disabled} onClick={disabled ? null : () => onClick()}>
      <Heading disabled={disabled}>{children}</Heading>
    </Control>
  );
};

const Control = styled.div`
  min-width: 145px;
  position: relative;
  border-radius: 5px;
  padding: 20px 50px 20px 25px;
  background-color: ${props =>
    props.disabled ? Colors.white : Colors.turquoise};
  transition: all, 0.3s;
`;

const Inner = styled.div`
  top: 4px;
  right: 4px;
  padding: 17px 8px;
  position: absolute;
  background-color: ${props =>
    props.disabled ? Colors.lightGrey : Colors.darkTurquoise};
  transition: all, 0.3s;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;

  svg {
    fill: white;
    width: 12px;
    height: 100%;
  }
`;

const Heading = styled(H5)`
  text-align: center;
  color: ${props => (props.disabled ? Colors.lightGrey : Colors.white)};
  transition: all, 0.3s;
`;

export default Basic;
