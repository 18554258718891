import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Typography from '../../consts/Typography';
import { B2 } from '../text-elements/index';
import Colors from '../../consts/Colors';
import { of } from 'rxjs';
import Devices from '../../consts/Devices';

const TextInput = ({ type, required, onInput, value }) => {
  const onTextInputChange = (el) => {
    onInput(el.target.value, type);
  };

  const [focus, setFocus] = useState(false);

  const typeParser = (focus) => {
    if (focus) {
      return '';
    } else {
      switch (type) {
        case 'name':
          return 'Your Full Name *';
        case 'email':
          return 'Email *';
        case 'company':
          return 'Company *';
        case 'job-title':
          return 'Job Title';
        case 'sector':
          return 'Sector';
      }
    }
  };

  return (
    <Wrapper required={required} focus={focus} value={value}>
      <Input
        type={'text'}
        name={type}
        // placeholder={typeParser(focus)}
        value={value}
        onChange={onTextInputChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      {value === '' ? (
        <Placeholder focus={focus}>{typeParser()}</Placeholder>
      ) : (
        <StaticPlaceholder focus={focus}>{typeParser()}</StaticPlaceholder>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 17px 12px 15px 12px;
  overflow: hidden;
  background-color: ${Colors.white};
  box-shadow: ${(props) => (props.focus ? Colors.boxShadow : null)};
  padding: ${(props) =>
    props.focus || props.value !== '' ? `23px 12px 9px 12px` : '17px 12px 15px 12px'};

  @media ${Devices.laptop} {
    transition: all 0.3s;
  }
`;

const Placeholder = styled(B2)`
  position: absolute;
  margin-bottom: 5px;
  color: ${Colors.black}80;
  font-size: ${(props) => (props.focus ? `8px` : `${Typography.fontSizes.mobile.b2}`)};
  transition: all 0.2s;
  pointer-events: none;

  ${(props) => (props.focus ? `top: 3px; left: 12px;` : ``)}
`;

const StaticPlaceholder = styled(B2)`
  position: absolute;
  margin-bottom: 5px;
  top: 3px;
  left: 12px;
  color: ${Colors.black}80;
  font-size: 8px;
  pointer-events: none;
`;

const Input = styled.input`
  border-style: none;
  outline: none;
  width: 100%;
  color: ${Colors.black};
  ${Typography.montserratRegular};
  font-size: 15px;
`;

export default TextInput;
