import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { H2, B2 } from "../text-elements/index";
import Colors from "../../consts/Colors";
import GenericCTA from "../ctas/generic/Generic";
import { ReactComponent as PhoneIcon } from "../../assets/svg/phone.svg";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right.svg";
import { ReactComponent as Cross } from "../../assets/svg/cross.svg";
import Devices from "../../consts/Devices";
import Modal from "../modal/Modal";

const QuestionTooltipModal = ({
  section,
  currentQuestionIndex,
  isQuestionAnswered,
  onArrowClick,
  onCrossClick,
  carouselItemWidth,
  telephone,
}) => {
  const question = section.questions[currentQuestionIndex];
  const doesQuestionHaveYesTooltip =
    question.yes_tooltip.title !== "" || question.yes_tooltip.body !== "";
  const doesQuestionHaveNoTooltip =
    question.no_tooltip.title !== "" || question.no_tooltip.body !== "";
  const questionAnswer = isQuestionAnswered(question);
  const tooltipAlreadyViewed = question.tooltipViewed;

  const modalWidthCalc = () => {
    if (carouselItemWidth) {
      const tenPercent = (carouselItemWidth / 100) * 15;
      return carouselItemWidth + tenPercent;
    }
  };

  const Skippable = (title) => {
    const titleLowercase = title.toLowerCase();
    switch (titleLowercase) {
      case "Legal Respresentation":
        return true;
        break;
      default:
        return false;
        break;
    }
  };

  let notAnswered;
  let answeredNo;
  let answeredYesWithoutTooltip;
  let answeredNoWithoutTooltip;
  let answeredYesWithTooltip;

  if (questionAnswer === undefined) {
    notAnswered = true;
  } else if (questionAnswer === false) {
    answeredNo = true;
  } else if (questionAnswer === true && !doesQuestionHaveYesTooltip) {
    answeredYesWithoutTooltip = true;
  } else if (questionAnswer === true && doesQuestionHaveYesTooltip) {
    answeredYesWithTooltip = true;
  }
  
  if (questionAnswer === false && !doesQuestionHaveNoTooltip) {
    answeredNoWithoutTooltip = true;
  }

  debugger;
  return (
    <>
      {notAnswered ||
      answeredYesWithoutTooltip ||
      answeredNoWithoutTooltip ||
      tooltipAlreadyViewed ? null : (
        <TooltipModal
          modalWidth={modalWidthCalc()}
          closeModal={() => {
            onCrossClick();
          }}
          hideCloseButton={true}
          component={
            <LilNasX>
              <>
                {answeredYesWithTooltip &&
                !question.no_tooltip.push_to_next ? null : (
                  <CrossWrapper
                    onClick={() => {
                      onCrossClick();
                    }}
                  >
                    <Cross />
                  </CrossWrapper>
                )}
              </>
              {answeredYesWithTooltip ? (
                <ArrowWrapper onClick={onArrowClick}>
                  <ArrowRight />
                </ArrowWrapper>
              ) : null}

              {question.no_tooltip.push_to_next ? (
                <ArrowWrapper onClick={onArrowClick}>
                  <ArrowRight />
                </ArrowWrapper>
              ) : null}

              <Inner>
                {answeredYesWithTooltip && !tooltipAlreadyViewed ? (
                  <>
                    <Header>{question.yes_tooltip.title}</Header>
                    <Body>{question.yes_tooltip.body}</Body>
                  </>
                ) : answeredNo && !tooltipAlreadyViewed ? (
                  <>
                    <Header>{question.no_tooltip.title}</Header>
                    <Body>{question.no_tooltip.body}</Body>
                    <a
                      href="#"
                      onClick={() => {
                        telephone("0800 988 7847");
                      }}
                    >
                      <CTAWrapper>
                        <CTA
                          onClick={() => {
                            onCrossClick();
                          }}
                          icon={<PhoneIcon />}
                        >
                          Call Brodies
                        </CTA>
                      </CTAWrapper>
                    </a>
                  </>
                ) : null}
              </Inner>
            </LilNasX>
          }
        ></TooltipModal>
      )}
    </>
  );
};

const TooltipModal = styled(Modal)`
  @media (max-width: 768px) {
    padding: 5px;
    transform: translateY(0%) translateX(-50%);
    bottom: 0px;
    top: auto;
    box-shadow: none;
    width: calc(100vw - 10px);
  }
`;

const LilNasX = styled.div`
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Inner = styled.div`
  padding: 0rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${Devices.laptop} {
    padding: 2rem 1rem;
  }
`;

const CrossWrapper = styled.div`
  cursor: pointer;
  z-index: 10;
  width: 27px;
  height: 27px;
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 15px;
    height: 10px;
    g {
      path {
        stroke: ${Colors.turquoise};
      }
    }
  }
`;

const ArrowWrapper = styled.div`
  cursor: pointer;
  z-index: 10;
  background: ${Colors.white};
  box-shadow: ${Colors.boxShadow};
  width: 27px;
  height: 27px;
  border-radius: 9999em;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${Colors.turquoise};

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 15px;
    height: 10px;
    path {
      fill: #fff;
    }
  }
`;

const Header = styled(H2)`
  text-align: center;
  /* margin-bottom: 1.5625rem; */
  margin-bottom: 2rem;
`;

const Body = styled(B2)`
  text-align: center;
  max-width: 250px;
  /* margin-bottom: 1.875rem; */
  margin-bottom: 2rem;
`;

const CTA = styled(GenericCTA)``;

const CTAWrapper = styled.div`
  cursor: pointer;
`;

export default QuestionTooltipModal;
