import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import B2 from '../../text-elements/body/B2';
import B1 from '../../text-elements/body/B1';
import Colors from '../../../consts/Colors';
import Interweave from 'interweave';
import Devices from '../../../consts/Devices';
import HelpCentreTextDivider from '../help-centre-text-divider/HelpCentreTextDivider';
import { ReactComponent as Plus } from '../../../assets/svg/plus.svg';
import { ReactComponent as Minus } from '../../../assets/svg/minus.svg';

const HelpCentreQuestion = ({ question, answer }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Wrapper onClick={() => setVisible(!visible)}>
        <QuestionWrapper visible={visible}>
          <Question>{question}</Question>
          {visible ? <Minus /> : <Plus />}
        </QuestionWrapper>

        <AnswerWrapper visible={visible}>
          <Answer>
            <Interweave content={answer} />
          </Answer>
        </AnswerWrapper>
      </Wrapper>
      <HelpCentreTextDivider visible={true} />
    </>
  );
};

const Wrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;

  @media ${Devices.laptop} {
    &:hover {
      div {
        opacity: 1;
      }
      svg {
        opacity: 1;
      }
    }
  }
`;

const QuestionWrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0.6)};
  transition: opacity, 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  svg {
    height: 14px;
    opacity: ${props => (props.visible ? 1 : 0.6)};
    transition: opacity, 0.4s;
  }
`;

const Question = styled(B1)`
  color: ${Colors.black};
  width: 100%;
`;

const AnswerWrapper = styled.div`
  max-height: ${props => (props.visible ? '1200px' : '0px')};
  transition: max-height, 0.8s;
  overflow: hidden;
`;

const Answer = styled(B2)`
  color: ${Colors.black};
  li {
    margin-bottom: 1rem;
  }

  ul {
    margin-top: 1rem;
  }
`;

export default HelpCentreQuestion;
