import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';
import history from '../../history';
import Carousel from '../../components/carousels/onboarding-carousel/OnboardingCarousel';
import StylePrefabs from '../../consts/StylePrefabs';
import Devices from '../../consts/Devices';
import { B1, H1 } from '../../components/text-elements/index';
import AppActions from '../../actions/AppActions';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Colors from '../../consts/Colors';

class OnboardingCarouselView extends React.Component {
  wrapperRef = React.createRef();

  componentDidMount() {
    disableBodyScroll(this.wrapperRef.current);

    this.props.setApp({
      hideMenu: true,
      showBlobs: false,
      showBackButton: false,
      showHeader: false,
      withinOnboarding: true
    });

    this.checkOnboardingStatus();
  }

  componentWillUnmount() {
    this.props.setApp({
      hideMenu: false,
      showBlobs: true,
      showHeader: true,
      withinOnboarding: false
    });

    clearAllBodyScrollLocks();
  }

  checkOnboardingStatus() {
    if (this.props.onboarding.onboardingPassed) {
      history.push('/');
    }
  }

  render() {
    return (
      <Wrapper ref={this.wrapperRef}>
        <CarouselWrapper>
          <Carousel history={history} platform={this.props.platform} />
        </CarouselWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper};
  ${StylePrefabs.fullHeightMobileNoHeader}
  padding-top: 0px !important;

  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${Devices.laptop} {
    ${StylePrefabs.fullHeightDesktop};
  }
`;

const CarouselWrapper = styled.div`
  z-index: 200;
`;

const mapStateToProps = (state) => {
  return { onboarding: state.onboarding, platform: state.app.platform };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setApp: (data) => {
      dispatch(AppActions.setApp(data));
    }
  };
};

const connectedOnboardingCarouselView = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingCarouselView);

export default withRouter(connectedOnboardingCarouselView);
