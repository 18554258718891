import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';
import Colors from '../../../consts/Colors';
import StylePrefabs from '../../../consts/StylePrefabs';
import ExpertsActions from '../../../actions/ExpertActions';
import HeroImage from '../../../components/hero-image/HeroImage';
import AppActions from '../../../actions/AppActions';
import H1 from '../../../components/text-elements/heading/H1';
import H2 from '../../../components/text-elements/heading/H2';
import H3 from '../../../components/text-elements/heading/H3';
import B1 from '../../../components/text-elements/body/B1';
import H4 from '../../../components/text-elements/heading/H4';
import Contact from '../../../components/ctas/contact/Contact';
import Post from '../../../components/post/Post';
import Devices from '../../../consts/Devices';
import { ReactComponent as Phone } from '../../../assets/svg/phone.svg';
import { ReactComponent as Mail } from '../../../assets/svg/mail.svg';
import ExpertCard from '../../../components/expert-card/ExpertCard';

const ExpertBio = ({ loadExpert, setApp, match, expert, experts, telephone, platform }) => {
  useEffect(() => {
    loadExpert(match.params.expertId);
    setApp({
      showBlobs: false,
      showBackButton: true,
      backButton: {
        path: `/experts`,
        text: `Contact our legal experts`
      }
    });
  }, []);

  useEffect(() => {
    loadExpert(match.params.expertId);
  }, [match.params.expertId]);

  if (!expert) {
    return <span></span>;
  }

  return (
    <Wrapper>
      <Hero image={expert.acf.profile_picture && expert.acf.profile_picture.url}></Hero>
      <Inner>
        <Row>
          <div>
            <Title>{expert.title.rendered}</Title>
            <Subtitle>
              {expert.acf.location} {' | '} {expert.acf.position}
            </Subtitle>
          </div>
          <ContactOptions>
            <a
              href="#"
              onClick={() => {
                telephone(expert.acf.telephone, platform);
              }}
            >
              <Contact icon={<Phone />}>
                <span>Call</span>
              </Contact>
            </a>
            <a href={`mailto:${expert.acf.email}`} target="_blank">
              <Contact icon={<Mail />}>
                <span>Email</span>
              </Contact>
            </a>
          </ContactOptions>
        </Row>

        <Post content={expert.content.rendered} />

        <ContactRow>
          <ContactRowTitle>Telephone</ContactRowTitle>
          <a
            href="#"
            onClick={() => {
              telephone(expert.acf.telephone, platform);
            }}
          >
            {expert.acf.telephone}
          </a>
        </ContactRow>
        <ContactRow>
          <ContactRowTitle>Email Address</ContactRowTitle>
          <a href={`mailto:${expert.acf.email}`} target="_blank">
            {expert.acf.email}
          </a>
        </ContactRow>
      </Inner>
      <Related>
        <RelatedTitle>Other Experts in area</RelatedTitle>
        {experts
          .filter((selExpert) => selExpert.id !== expert.id)
          .map((expert, i) => {
            return (
              <ExpertCard
                key={i}
                expert={expert}
                telephone={(number) => {
                  telephone(number, platform);
                }}
              />
            );
          })}
      </Related>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 4rem;
  max-width: 900px;
  margin: 0 auto;
  @media ${Devices.laptop} {
    max-width: none;
    padding-bottom: 0;
  }
`;

const Hero = styled(HeroImage)`
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.54) 0%,
      rgba(0, 0, 0, 0.35) 39%,
      rgba(0, 0, 0, 0) 99%,
      rgba(0, 0, 0, 0) 100%
    );
    height: 100px;
  }
  @media ${Devices.laptop} {
    border-radius: 5px;
    padding-top: 50%;
    background-position: center;
  }
`;

const Inner = styled.div`
  padding: 2.43rem 2rem;
  border-radius: 5px 5px 0 0;
  background: ${Colors.white};
  margin-top: -20px;
  @media ${Devices.laptop} {
    margin-top: 0;
    /* background: #fff; */

    > * {
      max-width: 800px;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
`;

const Title = styled(H1)`
  margin-bottom: 1rem;
`;

const RelatedTitle = styled(H2)`
  margin-bottom: 1.875rem !important;
`;

const Subtitle = styled(H3)`
  text-transform: uppercase;
  font-weight: 600;
`;

const ContactOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 1.875rem 0;
  > a {
    margin-right: 1rem;
  }
  @media ${Devices.laptop} {
    justify-content: flex-start;
    margin: 0;
  }
`;

const Related = styled.div`
  padding: 0rem 1rem 1rem;
  @media ${Devices.laptop} {
    background-color: #fff;
    > * {
      max-width: 800px;
      margin: 0 auto;
    }
  }
  h3 {
    margin-bottom: 1rem;
  }
`;

const Row = styled.div`
  @media ${Devices.laptop} {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 1.875rem;
  }
`;

const ContactRow = styled.div`
  margin: 1.875rem auto;

  a {
    color: ${Colors.turquoise};
    font-size: 16px;

    @media ${Devices.laptop} {
      &:hover {
        color: ${Colors.black};
      }
    }
  }
`;

const ContactRowTitle = styled(H4)`
  margin-bottom: 1rem;
`;

const mapStateToProps = (state) => {
  return {
    expert: state.experts.expert,
    experts: state.experts.experts,
    platform: state.app.platform
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadExpert: (slug) => {
      dispatch(ExpertsActions.loadExpert(slug));
    },
    setApp: (data) => {
      dispatch(AppActions.setApp(data));
    },
    telephone: (number, platform) => {
      dispatch(AppActions.telephone(number, platform));
    }
  };
};

const connectedExpertBio = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpertBio);

export default withRouter(connectedExpertBio);
