import { ADD_CHECKLIST, REMOVE_CHECKLIST, UPDATE_CHECKLIST } from "../actions/ActionTypes";

const initialState = {
  checklists: []
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case ADD_CHECKLIST:
      return {
        ...state,
        checklists: [
          ...state.checklists,
          {
            ...action.payload.checklist,
            id: action.payload.id,
            name: action.payload.checklist.title,
            update_time: new Date(),
            complete: false
          }
        ]
      };
    case REMOVE_CHECKLIST:
      return {
        checklists: state.checklists.filter(({ id }) => id !== action.payload)
      };
    case UPDATE_CHECKLIST:
      return {
        ...state,
        checklists: state.checklists.map((checklist, index) => {
          if (checklist.id === action.payload.id) {
            return {
              ...checklist,
              ...action.payload
            };
          }
          return checklist;
          // ...state,
          // checklists: [...action.payload]
        })
      };

    default:
      return state;
  }
}
