import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Slider from "react-slick"
import striptags from "striptags"
import moment from "moment"
import { Link } from "react-router-dom"
import Devices from "../../consts/Devices"
import Arrow from "../arrow/Arrow"
import Interweave from "interweave"

import H3 from "../text-elements/heading/H3"
import H4 from "../text-elements/heading/H4"
import B2 from "../text-elements/body/B2"
import B4 from "../text-elements/body/B4"

import Phone from "../../assets/images/Health_And_Safety_Phone-01.png"

const Articles = ({ current, category, itemView }) => {
  const settings = {
    dots: false,
    centerMode: false,
    infinite: false,
    // centerPadding: '50px',
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    arrows: true,
    draggable: false,
    nextArrow: <ArrowComp dir="right" />,
    prevArrow: <ArrowComp dir="left" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          arrows: false,
          centerPadding: "20px 0 0 0",
          draggable: true,
        },
      },
    ],
  }

  if (!category) {
    return <span />
  }

  if (current) {
    category.items = category.items.filter((item) => {
      return item.slug !== current.slug
    })
  }

  return (
    <Wrapper itemView={itemView}>
      {!itemView && <SectionTitle>Health and safety articles</SectionTitle>}
      <Slider {...settings}>
        {category.items.map((item) => {
          return (
            <Slide>
              <Link to={`/info-library/${item.slug}`}>
                <Inner>
                  <Image
                    className="article-image-div"
                    style={{
                      backgroundImage: `url(${item.featured_image_src})`,
                    }}
                  />
                  <ArticleSummary>
                    <Meta>
                      <div>
                        <B4>{moment(item.date).format("MMM Do YYYY")}</B4>
                      </div>
                    </Meta>
                    <ArticleTitle>
                      <Interweave content={item.title.rendered} />
                    </ArticleTitle>
                    <ArticleExcerpt>
                      <Interweave
                        content={striptags(
                          item.excerpt.rendered.substring(0, 100)
                        )}
                      />
                      ...
                    </ArticleExcerpt>
                  </ArticleSummary>
                </Inner>
              </Link>
            </Slide>
          )
        })}

        <Slide>
          <a
            href={`https://brodies.com/expertise/businesses-and-organisations/health-and-safety/`}
          >
            <Inner>
              <Image
                className="article-image-div blog-link"
                style={{
                  backgroundImage: `url(${Phone})`,
                }}
              />
              <ArticleSummary>
                <Meta>
                  <div>
                    {/* <B4>{moment(item.date).format("MMM Do YYYY")}</B4> */}
                  </div>
                </Meta>
                <ArticleTitle>
                  <Interweave content={"Read more on Brodies.com"} />
                </ArticleTitle>
                {/* <ArticleExcerpt>
                  <Interweave
                    content={"Read more of our blog posts on Brodies.com"}
                  />
                </ArticleExcerpt> */}
              </ArticleSummary>
            </Inner>
          </a>
        </Slide>
      </Slider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media ${Devices.laptop} {
    width: 820px;
    margin: 0 auto;

    .slick-track {
      margin-left: 10px;
    }
  }

  .slick-list {
    padding-bottom: 8px;
    overflow: visible;
  }

  button {
    &:before {
      color: ${Colors.turquoise};
      font-size: 50px;
    }
  }

  .slick-prev {
    right: 40px;
    z-index: 10;
  }

  ${(props) =>
    props.itemView &&
    `
    .slick-track{
      margin-left: 0;
    }
  `}
`

const SectionTitle = styled(H3)`
  padding: 2rem 1rem 1rem;
  max-width: 800px;
  margin: 0 auto;
  @media ${Devices.laptop} {
    padding: 2.75rem 1rem 2.5rem 0;
  }
`

const Slide = styled.div`
  width: 100%;

  a {
    text-decoration: none;
    color: inherit;
  }
`

const Inner = styled.div`
  margin: 0 1rem;
  background: ${Colors.white};
  box-shadow: ${Colors.boxShadow};
  border-radius: 5px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  @media ${Devices.laptop} {
    &:hover {
      .article-image-div {
        transition: opacity 0.2s ease-out;
        opacity: 0.5;
      }
    }
  }
`

const ArticleTitle = styled(H4)`
  margin: 0.33125rem 0;
  padding: 0 1rem;
  @media ${Devices.laptop} {
    height: 41px;
    overflow: hidden;
  }
`

const ArticleExcerpt = styled(B2)`
  padding: 0 1rem;
  display: block;
`

const Meta = styled.div`
  padding: 0 1rem 0rem;

  span {
    font-size: 12px;
    line-height: 12px;
    color: #a6a6a6;
  }
`

const Image = styled.div`
  width: 100%;
  height: 250px;
  background-size: cover !important;
  background-position: center !important;

  &.blog-link {
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }
`

const ArrowComp = styled(Arrow)`
  position: absolute;
  top: -50px;
  right: 0;
  left: auto;
`

const ArticleSummary = styled.div`
  padding: 1rem;
  min-height: 240px;
  @media ${Devices.laptop} {
    min-height: 0;
  }
`

export default Articles
