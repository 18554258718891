import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';

import Colors from '../../consts/Colors';
import H6 from '../text-elements/heading/H6';
import H4 from '../text-elements/heading/H4';
import { ReactComponent as Mail } from '../../assets/svg/mail.svg';
import Devices from '../../consts/Devices';

const ExpertCard = ({ expert, key, telephone }) => {
  console.log(telephone);
  return (
    <Wrapper>
      <Expert>
        <LinkWrapper>
          <Link key={expert.id} to={`/experts/${expert.slug}`} key={key}>
            <Image
              image={expert.acf.profile_picture && expert.acf.profile_picture.sizes.medium_large}
            />
            <Details>
              <Location>{expert.acf.location}</Location>
              <Name>{expert.title.rendered}</Name>
            </Details>
          </Link>
        </LinkWrapper>
        <Contact>
          <a
            href={`#`}
            onClick={() => {
              telephone(expert.acf.telephone);
            }}
          >
            <div>
              <i className="fa fa-phone"></i>
            </div>
          </a>
          <a href={`mailto:${expert.acf.email}`} target="_blank">
            <div>
              <Mail />
            </div>
          </a>
        </Contact>
      </Expert>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  a {
    color: inherit;
    display: flex;
    flex: 1;
  }

  svg {
    path {
      stroke: #000000;
    }
  }
`;

const Expert = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 3px 6px #7777770d;
  background: ${Colors.white};
  border: 1px solid #f8f8f8;
  justify-content: space-between;
`;

const LinkWrapper = styled.div`
  width: 100%;
  background: ${Colors.white};

  transition: all 0.2s ease-out;
  @media ${Devices.laptop} {
    &:hover {
      box-shadow: none;
      background: ${Colors.altGrey};
    }
  }
`;

const Image = styled.div`
  background: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  width: 105px;
`;

const Details = styled.div`
  flex: 4;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${Devices.laptop} {
    padding: 1.5rem 2rem;
  }
`;

const Location = styled(H6)`
  margin-bottom: 0.5rem;
  font-weight: 100;
  text-transform: capitalize;
`;

const Name = styled(H4)``;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 3px 6px #7777770d;
  position: relative;
  z-index: 2;

  > a {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);

    transition: all 0.2s ease-out;
    @media ${Devices.laptop} {
      &:hover {
        box-shadow: none;
        background: ${Colors.turquoise};

        i {
          color: ${Colors.white};
        }

        path {
          stroke: ${Colors.white};
        }
      }
    }
  }

  a:last-of-type {
    border-bottom: 0px solid;
  }
`;

export default ExpertCard;
