import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import H5 from '../../text-elements/heading/H5';
import { ReactComponent as ArrowLeft } from '../../../assets/svg/arrow-left.svg';
import Colors from '../../../consts/Colors';

const Next = ({ onClick, children, disabled }) => {
  return (
    <Control disabled={disabled} onClick={disabled ? null : () => onClick()}>
      <Inner disabled={disabled}>
        <ArrowLeft />
      </Inner>
      <Heading disabled={disabled}>{children}</Heading>
    </Control>
  );
};

const Control = styled.div`
  cursor: pointer;
  /* min-width: 145px; */
  /* position: relative; */
  border-radius: 9999em;
  padding: 0.3125rem 1rem;
  background-color: ${Colors.turquoise};
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
`;

const Inner = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    transition: all 0.2s;
    fill: white;
    width: 12px;
    height: 100%;
  }
`;

const Heading = styled(H5)`
  cursor: pointer;
  text-align: center;
  margin-left: 0.75rem;
  opacity: ${(props) => (props.disabled ? 0.5 : 1.0)};
  color: ${(props) => (props.textColor ? props.textColor : Colors.white)};
  transition: all, 0.3s;
  text-align: center;
`;

export default Next;
