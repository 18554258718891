import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

const HeroImage = ({ className, image }) => {
  return <Wrapper image={image} className={className}></Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
  height: 40vh;
  min-height: 250px;
  background: url(${props => props.image}) center;
  background-size: cover;
`;

export default HeroImage;
