import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';
import AppActions from '../../actions/AppActions';
import ChecklistActions from '../../actions/ChecklistActions';
import StylePrefabs from '../../consts/StylePrefabs';
import H1 from '../../components/text-elements/heading/H1';
import H3 from '../../components/text-elements/heading/H3';
import B1 from '../../components/text-elements/body/B1';
import Colors from '../../consts/Colors';
import Utils from '../../consts/Utils';
import Devices from '../../consts/Devices';
import InProgressCard from '../../components/inprogress-card/InprogressCard';

import Card from '../../components/card/Card';

class Checklists extends React.Component {
  state = {
    hovered: null
  };

  componentDidMount() {
    this.props.setApp({
      showBackButton: true,
      backButton: { path: '/', text: null },
      showBlobs: true
    });
    this.props.loadChecklists();
  }

  handleMouseOver(index) {
    if (window.innerWidth <= 425) {
      return;
    } else {
      this.setState({ hovered: index });
    }
  }
  render() {
    return (
      <Wrapper>
        <Title>Start a Checklist</Title>

        <Cards>
          <CardsInner>
            {this.props.checklists.length == 0 && (
              <Card backgroundColor={Colors.cream} type={'LOADING'} />
            )}

            {this.props.checklists.map((checklist, i) => {
              if (checklist == null) {
                return <Card key={i} type={'LOADING'} />;
              }
              return (
                <Card
                  key={i}
                  index={i}
                  onMouseOver={() => this.handleMouseOver(i)}
                  onMouseOut={() => this.handleMouseOver(null)}
                  hovered={this.state.hovered}
                  type={'CHECKLIST'}
                  title={checklist.title}
                  name={checklist.name}
                  to={`/checklist/new/${checklist.id}`}
                  icon={checklist.image}
                  backgroundColor={Colors.cream}
                  sectionsLength={checklist.sections.length}
                />
              );
            })}
          </CardsInner>
        </Cards>

        <InProgressBlocks>
          <InProgressBlock>
            <H3>In progress checklists</H3>
            {this.props.savedChecklists.filter(checklist => checklist.complete !== true).length >
            0 ? (
              <div>
                {this.props.savedChecklists
                  .filter(checklist => checklist.complete !== true)
                  .map((checklist, i) => {
                    return (
                      <InProgressCard
                        index={i}
                        icon={checklist.image}
                        title={checklist.title}
                        name={checklist.name}
                        date={checklist.update_time}
                        to={`/checklist/saved/${checklist.id}`}
                        removeChecklist={() => {
                          this.props.removeChecklist(checklist.id);
                        }}
                      />
                    );
                  })}
              </div>
            ) : (
              <EmptyState>
                <B1>Start a checklist to see in progress</B1>
              </EmptyState>
            )}
          </InProgressBlock>
          <InProgressBlock>
            <H3>Completed Checklists</H3>
            {this.props.savedChecklists.filter(checklist => checklist.complete === true).length >
            0 ? (
              <div>
                {this.props.savedChecklists
                  .filter(checklist => checklist.complete === true)
                  .map(checklist => {
                    return (
                      <InProgressCard
                        icon={checklist.image}
                        title={checklist.title}
                        name={checklist.name}
                        date={checklist.update_time}
                        to={`/checklist/saved/${checklist.id}`}
                        removeChecklist={() => {
                          this.props.removeChecklist(checklist.id);
                        }}
                      />
                    );
                  })}
              </div>
            ) : (
              <EmptyState>
                <B1>Finish a checklist to see completed</B1>
              </EmptyState>
            )}
          </InProgressBlock>
        </InProgressBlocks>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper};
`;

const Title = styled(H1)`
  color: ${Colors.black};
  text-align: center;
  /* margin-bottom: 34px; */
  margin-bottom: 1.7222222222rem;

  @media ${Devices.laptop} {
    text-align: left;
    margin-bottom: 2rem;
  }
`;

const Cards = styled.div`
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: -4px;
    right: -4px;
    background: #232126;
    height: 20px;
    z-index: 0;
    @media ${Devices.laptop} {
      display: none;
    }
  }
`;

const CardsInner = styled.div`
  background: ${Colors.backgroundGrey};
  padding: 0 1rem 1.5rem;
  border-radius: 0 0 10px 10px;
  position: relative;
  z-index: 2;
  > div {
    margin-bottom: 10px;
    /* position: relative;
    top: -55px; */
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media ${Devices.laptop} {
    padding: 0;
    background: transparent;
    display: flex;
    margin-bottom: 0;
    > div {
      top: 0;
      width: 300px;
      margin-bottom: 0px;
      margin-right: 20px;
    }

    > div,
    > div > div,
    h4 {
      transition: background 0.2s ease-out;
    }

    > div {
      transform: scale(1);
      will-change: transform;
      &:hover {
        background: ${Colors.turquoise};
        .title {
          background: ${Colors.turquoise};
          h4 {
            color: ${Colors.white};
          }

          span {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }
`;

const InProgressBlocks = styled.div`
  background: #232126;
  padding: 1rem;
  position: relative;
  z-index: 1;
  margin-left: -4px;
  width: calc(100% + 8px);

  @media ${Devices.laptop} {
    padding: 0;
    background: transparent;
    margin-left: 0;
    width: 100%;
  }

  h3 {
    color: ${Colors.white};

    @media ${Devices.laptop} {
      color: ${Colors.black};
    }
  }
`;

const InProgressBlock = styled.div`
  margin: 36px 0;
  h3 {
    margin-bottom: 20px;
  }

  @media ${Devices.laptop} {
    > div {
      background: ${Colors.cream};
      border: 2px dashed ${Colors.lightGrey};
      border-radius: 5px;
      padding: 1rem;
    }
  }
`;

const EmptyState = styled.div`
  padding: 1rem;
  border: 1px dashed #fff;
  opacity: 0.5;
  border-radius: 10px;
  span {
    color: #fff;
    @media ${Devices.laptop} {
      color: ${Colors.lightGrey};
    }
  }
`;

const mapStateToProps = state => {
  return {
    app: state.app,
    checklists: state.app.checklists,
    savedChecklists: state.savedChecklists.checklists
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setApp: data => {
      dispatch(AppActions.setApp(data));
    },
    loadChecklists: () => {
      dispatch(ChecklistActions.loadChecklists());
    },
    removeChecklist: id => {
      dispatch(ChecklistActions.removeChecklist(id));
    }
  };
};

const connectedChecklists = connect(
  mapStateToProps,
  mapDispatchToProps
)(Checklists);

export default withRouter(connectedChecklists);
