import {
  LOAD_EXPERTS,
  LOAD_EXPERT,
  CLEAR_EXPERT
} from "../actions/ActionTypes";

const initialState = {
  experts: [],
  expert: null
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_EXPERTS:
      return {
        ...state,
        experts: action.payload
      };
    case CLEAR_EXPERT:
      return {
        ...state,
        expert: null
      };
    case LOAD_EXPERT:
      return {
        ...state,
        expert: action.payload
      };
    default:
      return state;
  }
}
