import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';
import AppActions from '../../../actions/AppActions';
import NewsActions from '../../../actions/NewsActions';
import Colors from '../../../consts/Colors';
import HeroImage from '../../../components/hero-image/HeroImage';
import Post from '../../../components/post/Post';
import H3 from '../../../components/text-elements/heading/H3';
import H1 from '../../../components/text-elements/heading/H1';
import H2 from '../../../components/text-elements/heading/H2';
import H4 from '../../../components/text-elements/heading/H4';
import Button from '../../../components/ctas/basic/Basic';
import CTA from '../../../components/ctas/next/Next';
import InfoCarousel from '../../../components/carousels/info-carousel/InfoCarousel';
import Devices from '../../../consts/Devices';
import moment from 'moment';
import Articles from '../../../components/articles/Articles';
import Category from '../../../components/category/Category';
import Interweave from 'interweave';
import { Helmet } from 'react-helmet';

class InfoLibraryContentItem extends React.Component {
  componentDidMount() {
    this.props.loadPost(this.props.match.params.contentId);
    this.props.setApp({
      showBlobs: false,
      showBackButton: true,
      backButton: {
        path: `/info-library`,
        text: 'Information Library'
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.article == null || prevProps.article == null) {
      return;
    } else if (this.props.match.params.contentId !== prevProps.article.slug) {
      this.props.loadPost(this.props.match.params.contentId);
    }
  }

  getYTID = item => {
    if (!item) {
      return;
    }

    if (item.acf.youtube_link) {
      var r,
        rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      r = item.acf.youtube_link.match(rx);
      return r[1];
    }
  };

  htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }

  render() {
    let { article } = this.props;

    if (!article) {
      return <span></span>;
    }
    return (
      <Wrapper>
        <Helmet>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={article.featured_image_src} />
          <meta property="og:title" content={this.htmlDecode(article.title.rendered)} />
        </Helmet>

        {article.category == 'Video' && (
          <VideoWrapper>
            <iframe
              id="ytplayer"
              type="text/html"
              width="640"
              height="360"
              src={`https://www.youtube.com/embed/${this.getYTID(article)}?autoplay=0`}
              frameborder="0"
            ></iframe>
          </VideoWrapper>
        )}

        {article.category !== 'Video' && <Hero image={article.featured_image_src} />}

        <Content>
          <Title>
            <Interweave content={article.title.rendered} />
          </Title>
          <DateTitle>{moment(article.modified).format('D MMM YYYY')}</DateTitle>
          <Article content={article.content.rendered}></Article>

          {article.category == 'Guide' && (
            <a
              href={`${article.acf.file}`}
              target="_blank"
              style={{
                maxWidth: 400,
                display: `block`
              }}
            >
              <CTA onClick={() => {}}>
                <span>Download Guide</span>
              </CTA>
            </a>
          )}

          <Share
            onClick={() => {
              this.props.openShareSheet({
                url: window.location.href
              });
            }}
          >
            <H4>SHARE</H4>
          </Share>
        </Content>

        <Related>
          <RelatedTitle>Related {article.category}s</RelatedTitle>

          <Articles
            itemView
            current={article}
            category={
              this.props.news.filter(items => items.category == article.category.toLowerCase())[0]
            }
          />
        </Related>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding-bottom: 4rem;
  max-width: 900px;
  margin: 0 auto;

  @media ${Devices.laptop} {
    max-width: none;
    padding-bottom: 0;
    .slick-track {
      margin-left: -15px;
    }
  }

  a {
    margin: 1rem 0;
    color: inherit;
    text-decoration: none;
    @media ${Devices.laptop} {
    }
  }
`;

const Hero = styled(HeroImage)`
  height: 66vh;
  @media ${Devices.laptop} {
    border-radius: 5px;
    min-height: 640px;
  }
`;
const Content = styled.div`
  padding: 2rem 2rem;
  background: ${Colors.white};
  margin-top: -20px;
  @media ${Devices.laptop} {
    margin-top: 0;
    padding: 3.4375rem 0rem;

    > * {
      max-width: 800px;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
`;

const Title = styled(H1)`
  margin-bottom: 0.9375rem;
`;

const RelatedTitle = styled(H2)`
  margin-bottom: 2.5rem !important;
`;

const Article = styled(Post)``;

const Buttons = styled.div`
  margin-top: 2rem;
  > div {
    margin: 1rem;
  }
`;

const Related = styled.div`
  padding: 2rem 1rem;
  @media ${Devices.laptop} {
    background-color: #fff;
    > * {
      max-width: 800px;
      margin: 0 auto;
    }
  }
`;

const DateTitle = styled(H3)`
  margin-bottom: 1.875rem;
`;

const ArticleSummary = styled.div`
  padding: 1rem;
`;

const Share = styled.div`
  cursor: pointer;
  margin-top: 2.5em;
  h4 {
    color: ${Colors.turquoise};
  }
`;

const VideoWrapper = styled.div`
  width: 100%;

  overflow: hidden;
  iframe {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 5px;
  }

  @media ${Devices.laptop} {
    border-radius: 5px;
    overflow: hidden;
    min-height: 640px;
    iframe {
      /* position: absolute; */
      min-height: 640px;
    }
  }
`;

const mapStateToProps = state => {
  return {
    article: state.article,
    news: state.news
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setApp: data => {
      dispatch(AppActions.setApp(data));
    },
    openShareSheet: url => {
      dispatch(AppActions.openShareSheet(url));
    },
    loadPost: id => {
      dispatch(NewsActions.loadPost(id));
    }
  };
};

const connectedInfoLibraryContentItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoLibraryContentItem);

export default withRouter(connectedInfoLibraryContentItem);
