import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import AppActions from '../../actions/AppActions';
import OnboardingActions from '../../actions/OnboardingActions';
import { connect } from 'react-redux';
import H1 from '../../components/text-elements/heading/H1';
import history from '../../history';
import Next from '../../components/ctas/next/Next';
import ScrollView from '../../components/scroll-view/ScrollView';
import StylePrefabs from '../../consts/StylePrefabs';
import GdprText from '../../components/gdpr-text/GdprText';
import OnboardingHeading from '../../components/onboarding-heading/OnboardingHeading';
import TextOnlyCTA from '../../components/ctas/text-only/TextOnly';
import Devices from '../../consts/Devices';
import { withRouter, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Sizes from '../../consts/Sizes';

class GdprView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrolled: true
    };

    this.checkOnboardingStatus = this.checkOnboardingStatus.bind(this);
    this.onScrollViewScroll = this.onScrollViewScroll.bind(this);
    this.handleCtaClick = this.handleCtaClick.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth > 1024) {
      this.setState({ scrolled: true });
    }
    this.props.setApp({
      hideMenu: true,
      showBlobs: false,
      showBackButton: true,
      backButton: {
        path: `/onboarding/2`,
        text: null
      },
      withinOnboarding: true
    });

    this.checkOnboardingStatus();
  }

  componentWillUnmount() {
    this.props.setApp({
      hideMenu: false,
      showBlobs: true,
      withinOnboarding: false
    });
  }

  checkOnboardingStatus() {
    if (this.props.onboarding.onboardingPassed) {
      history.push('/');
    }
  }

  onScrollViewScroll(el) {
    let maxScroll = el.target.scrollHeight - el.target.clientHeight;
    let currentScroll = el.target.scrollTop;

    if (currentScroll >= maxScroll) {
      this.setState({ scrolled: true });
    }
  }

  handleCtaClick() {
    this.props.subscribe(this.props.onboarding);
    this.props.setOnboardingStatus({
      gdprAccepted: true,
      onboardingPassed: true
    });

    history.push(this.props.forwardingPath || '/');
  }

  render() {
    // let ctaText = this.state.scrolled ? 'Submit' : 'Scroll to accept';
    let ctaText = 'Accept disclaimer';

    return (
      <Wrapper>
        <OnboardingHeading>Legal disclaimer</OnboardingHeading>
        {/* <ScrollView onScroll={el => this.onScrollViewScroll(el)}> */}
        <ScrollView>
          <GdprText />
        </ScrollView>
        <ButtonWrapper>
          <NextBtn disabled={this.state.scrolled ? false : true} onClick={this.handleCtaClick}>
            {ctaText}
          </NextBtn>
          <a target="_blank" href="https://brodies.com/privacy-notice">
            <TextOnlyCTA>Privacy notice</TextOnlyCTA>
          </a>
          <a target="_blank" href="https://brodies.com/cookie-information">
            <TextOnlyCTA>How we use cookies</TextOnlyCTA>
          </a>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper};
  ${StylePrefabs.fullHeightMobileWithHeader};
  /* padding-top: 4.5rem !important; */
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${Devices.laptop} {
    min-height: 0px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media ${Devices.laptop} {
    margin-top: 2.25rem;
  }
`;

const NextBtn = styled(Next)`
  margin: 10px 0px 20px;
`;

const mapStateToProps = (state) => {
  return { onboarding: state.onboarding, forwardingPath: state.app.forwardingPath };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setApp: (data) => {
      dispatch(AppActions.setApp(data));
    },
    setOnboardingStatus: (data) => {
      dispatch(OnboardingActions.setOnboardingStatus(data));
    },
    subscribe: (data) => {
      dispatch(OnboardingActions.subscribe(data));
    }
  };
};

const connectedGdprView = connect(
  mapStateToProps,
  mapDispatchToProps
)(GdprView);

export default withRouter(connectedGdprView);
