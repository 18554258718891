import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import { ReactComponent as ArrowLeft } from '../../assets/svg/arrow-left-sml.svg';
import H6 from '../text-elements/heading/H6';
import Devices from '../../consts/Devices';

const Back = ({ onClick, text }) => {
  return (
    <Wrapper onClick={onClick}>
      <Inner>
        <Arrow>
          <ArrowLeft />
        </Arrow>
        {text ? (
          <H6>
            Back to <a>{text}</a>
          </H6>
        ) : (
          <H6>Back</H6>
        )}
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media ${Devices.laptop} {
    position: absolute;
    left: 3rem;
    top: 35px;
    background: ${Colors.white};
    box-shadow: ${Colors.boxShadow};
    border-radius: 999em;
    display: flex;
    padding: 0.3125rem 1rem;
    transition: all 0.2s ease-out;
    &:hover {
      background: ${Colors.turquoise};
      svg {
        path {
          fill: ${Colors.white};
          stroke: transparent;
        }
      }
      h6 {
        color: ${Colors.white};
      }
      a {
        margin-left: 4px;
        color: ${Colors.white};
      }
    }
  }
  h6 {
    color: ${Colors.black};
    @media ${Devices.laptop} {
      color: ${Colors.black};
      justify-content: center;
      display: flex;
      flex-direction: row;

      a {
        margin-left: 4px;
        color: ${Colors.turquoise};
      }
    }
  }
`;

const Inner = styled.div`
  /* background: ${Colors.turquoise}; */
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  @media ${Devices.laptop} {
    flex-direction: row;
    padding: 0;
  }
`;

const Arrow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  @media ${Devices.laptop} {
    margin-bottom: 0;
    margin-right: 15px;
  }
  svg {
    margin: 0 auto;
    width: 9px;
    height: 9px;
    path {
      fill: ${Colors.turquoise};
      stroke: transparent;
    }
  }
`;

export default Back;
