import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Colors from '../../../consts/Colors';
import moment from 'moment';
import striptags from 'striptags';

import H4 from '../../text-elements/heading/H4';
import B2 from '../../text-elements/body/B2';
import B4 from '../../text-elements/body/B4';

const InfoCarousel = ({ className, items }) => {
  const settings = {
    dots: false,
    centerMode: true,
    infinite: true,
    centerPadding: '20px 0 0 0',
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    adaptiveHeight: false
  };
  return (
    <Wrapper className={className}>
      <Slider {...settings}>
        {items.map(item => {
          return (
            <Slide>
              <Link to={`/info-library/${item.slug}`}>
                <Inner>
                  <Image
                    style={{
                      backgroundImage: `url(${item.featured_image_src})`
                    }}
                  />
                  <ArticleSummary>
                    <Meta>
                      <div>
                        <B4>{moment(item.date).format('MMM Do YYYY')}</B4>
                      </div>
                    </Meta>
                    <ArticleTitle>{item.title.rendered}</ArticleTitle>
                    <ArticleExcerpt>
                      {striptags(item.excerpt.rendered.substring(0, 100))}...
                    </ArticleExcerpt>
                  </ArticleSummary>
                </Inner>
              </Link>
            </Slide>
          );
        })}
      </Slider>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const CarouselItem = styled.div``;

const Slide = styled.div`
  width: 100%;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Inner = styled.div`
  margin: 0 1rem;
  background: ${Colors.white};
  box-shadow: ${Colors.boxShadow};
  border-radius: 15px;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  border: 3px solid #fff;
`;

const ArticleTitle = styled(H4)`
  margin: 1rem 0 0.5rem;
  padding: 0 1rem;
`;

const ArticleExcerpt = styled(B2)`
  padding: 0 1rem;
  margin-bottom: 0.5rem;
  display: block;
`;

const Meta = styled.div`
  padding: 0 1rem 1rem;
`;

const Image = styled.div`
  width: 100%;
  background-size: cover !important;
  background-position: center !important;
`;

const ArticleSummary = styled.div`
  padding: 1rem;
`;
export default InfoCarousel;
