import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import { H2 } from '../text-elements/index';
import Devices from '../../consts/Devices';

const OnboardingHeading = ({ children, className }) => {
  return (
    <>
      <Bar />
      <Heading className={className}>{children}</Heading>
    </>
  );
};

const Heading = styled(H2)`
  max-width: 244px;
  text-align: center;
  margin-bottom: 1rem;

  @media ${Devices.mobileTall} {
    margin-bottom: 1.5rem;
  }

  @media ${Devices.laptop} {
    margin-bottom: 2.25rem;
    max-width: 344px;
  }

  @media ${Devices.laptopL} {
    margin-bottom: 4.5rem;
  }
`;

const Bar = styled.div`
  border-radius: 5px;
  width: 12px;
  border: 1px solid ${Colors.turquoise};
  margin-bottom: 0.5rem;
  @media ${Devices.mobileTall} {
    margin-bottom: 1.5rem;
  }
`;

export default OnboardingHeading;
