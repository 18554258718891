import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Utils from '../../consts/Utils';
import Colors from '../../consts/Colors';
import Devices from '../../consts/Devices';
import { ReactComponent as Phone } from '../../assets/svg/phone.svg';

import H6 from '../text-elements/heading/H6';

const Call = ({ platform, phone }) => {
  let invertColor = window.location.href.indexOf('experts/') > -1;

  return (
    <Wrapper
      invert={invertColor}
      onClick={() => {
        phone('0800 988 7847');
      }}
    >
      <Phone />
      <H6>{platform == 'Desktop' ? 'Get in touch' : 'Call'}</H6>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 7px 12px;
  cursor: pointer;

  @media ${Devices.laptop} {
    &:hover {
      h6 {
        color: ${Colors.turquoise};
        transition: all 0.2s ease-out;
      }

      svg path {
        fill: ${Colors.turquoise};
        transition: all 0.2s ease-out;
      }
    }
  }

  h6 {
    color: ${(props) => (props.invert ? '#fff' : '#000')};
    margin-top: 5px;
  }

  svg path {
    fill: ${(props) => (props.invert ? '#fff' : '#000')};
  }
`;

export default Call;
