import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import Utils from "../../consts/Utils"
import Colors from "../../consts/Colors"

import H6 from "../text-elements/heading/H6"

const Menu = ({ ref, telephone }) => {
  return (
    <Wrapper ref={ref}>
      <Links>
        <Link to="/">
          <PrimaryLink>
            <H6>Home</H6>
          </PrimaryLink>
        </Link>
        <Link to="/checklists">
          <PrimaryLink>
            <H6>Checklists</H6>
          </PrimaryLink>
        </Link>
        <Link to="/experts">
          <PrimaryLink>
            <H6>Contact an expert</H6>
          </PrimaryLink>
        </Link>
        <Link to="/reader/statement">
          <PrimaryLink>
            <H6>Giving a HSE / Police Statement</H6>
          </PrimaryLink>
        </Link>
        <Link to="/info-library">
          <PrimaryLink>
            <H6>Information Library</H6>
          </PrimaryLink>
        </Link>
        <Link to="/help-centre">
          <PrimaryLink>
            <H6>FAQs</H6>
          </PrimaryLink>
        </Link>
        <a href="https://brodies.com/" target="_blank">
          <ExternalLink>
            <H6>Go to brodies.com</H6>
          </ExternalLink>
        </a>
        {/* <Link
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          <PrimaryLink>
            <H6>Reset App</H6>
          </PrimaryLink>
        </Link> */}
        <a
          href="#"
          onClick={() => {
            telephone("0800 988 7847")
          }}
        >
          <CallLink>
            <H6>Call Brodies</H6>
          </CallLink>
        </a>
      </Links>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${Colors.white};
  border-radius: 0 0 15px 15px;
  a {
    color: inherit;
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  div {
    height: 70px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 35px;
  }
`

const PrimaryLink = styled.div``

const CallLink = styled.div`
  background: ${Colors.turquoise};
  height: 70px;
  border-radius: 0 0 15px 15px;
  border-width: 0px !important;
  margin: 0 !important;
  h6 {
    color: ${Colors.white};
  }
`

const ExternalLink = styled.div`
  height: 70px;
`

const SecondaryLink = styled.div``

export default Menu
