import Utlis from './Utils';
import Devices from './Devices';
import Typography from './Typography';
import Colors from './Colors';

export default {
  boxShadow: 'rgba(0,0,0,0.06) 0px 3px 6px;',

  appWrapper: `
  // padding: 87px 0 0 0;
    background-color: ${Colors.white};
    // min-height: 100vh;

  `,

  pageWrapper: `
  padding-top: 2rem;
  position: relative;
  margin: 4px 4px;
  width: calc(100% - 8px);
  background: ${Colors.backgroundGrey};
  border-radius: 10px;

  @media ${Devices.laptop} {
    padding-top: 7.5rem;
    margin-top: 0;
    flex: 1;
    width: auto;
    background: transparent;
    max-width: 800px;
    margin: 0 auto;
  }

  @media ${Devices.laptopL} {
    padding-top: 8.75rem;
  }

  `,

  fullHeightMobileNoHeader: `
  min-height: calc(var(--vh, 1vh) * 100 - 8px);
  `,

  fullHeightMobileWithHeader: `
  min-height: calc(var(--vh, 1vh) * 100 - 108px);
  `,

  fullHeightDesktop: `
    // min-height: calc(100vh - 2rem);
    min-height: 100vh;
  }`
};
