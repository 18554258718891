import { LOAD_HELPCENTRE } from '../actions/ActionTypes';

const initialState = { helpSections: [] };

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_HELPCENTRE:
      return { helpSections: [...action.payload] };
    default:
      return state;
  }
}
