import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import Colors from '../../consts/Colors';
import moment from 'moment';
import Devices from '../../consts/Devices';

import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import { ReactComponent as Check } from '../../assets/svg/check.svg';

import H4 from '../text-elements/heading/H4';
import B3 from '../text-elements/body/B3';

const InprogressCard = ({ icon, title, name, date, to, history, removeChecklist }) => {
  return (
    <Wrapper>
      <div>
        <Icon>
          <img src={icon} />
        </Icon>
      </div>
      <div style={{ flex: 6 }}>
        <Title className="title">
          <H4>{title !== name ? `${title} - ${name}` : title}</H4>
          <B3>Last updated {moment(date).fromNow()}</B3>
        </Title>
      </div>
      <div>
        <Buttons>
          <Delete onClick={removeChecklist}>
            <Check />
          </Delete>
          <Arrow
            onClick={() => {
              if (to) {
                history.push(to);
              }
            }}
          >
            <ArrowRight />
          </Arrow>
        </Buttons>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: ${Colors.white};
  border-radius: 15px;
  justify-content: space-between;
  position: relative;
  padding: 1rem;
  margin-bottom: 10px;
  /* max-height: 60px; */

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    /* max-height: 60px; */
  }
`;

const Icon = styled.div`
  img {
    width: 70%;
  }
  background: transparent !important;
`;

const Title = styled.div`
  span {
    font-weight: lighter;
  }
`;

const Arrow = styled.div`
  background: ${Colors.turquoise};
  box-shadow: ${Colors.boxShadow};
  width: 27px;
  height: 27px;
  border-radius: 9999em;
  position: relative;
  transition: all 0.2s ease-out;
  @media ${Devices.laptop} {
    &:hover {
      background: ${Colors.white};
      svg {
        path {
          fill: ${Colors.turquoise};
        }
      }
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 15px;
    height: 10px;
    path {
      fill: #fff;
    }
  }
`;

const Delete = styled.div`
  background: ${Colors.cream};
  box-shadow: ${Colors.boxShadow};
  width: 27px;
  height: 27px;
  border-radius: 9999em;
  position: relative;

  transition: all 0.2s ease-out;

  @media ${Devices.laptop} {
    &:hover {
      background: ${Colors.turquoise};
      svg {
        path {
          stroke: ${Colors.white};
        }
      }
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 15px;
    height: 10px;
    path {
      fill: #000;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    margin-left: 10px;
    cursor: pointer;
  }
`;

export default withRouter(InprogressCard);
