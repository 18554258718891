import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import H6 from "../text-elements/heading/H6"
import Colors from "../../consts/Colors"
import Devices from "../../consts/Devices"
import { ReactComponent as Cross } from "../../assets/svg/cross.svg"
import { ReactComponent as Tick } from "../../assets/svg/tick-yesno.svg"

const YesNoToggle = ({ selected, onClick, isNextOption }) => {
  const emailUpdates = (value) => {
    if (value === selected) {
      return true
    } else {
      return false
    }
  }

  return (
    <Wrapper className="yes-no-toggle">
      {!isNextOption ? (
        <>
          <Left
            selected={emailUpdates(false)}
            onClick={() => {
              onClick(false)
            }}
          >
            <Cross />
            <LHeading selected={emailUpdates(false)}>No</LHeading>
          </Left>
          <Border selected={selected} />
          <Right
            selected={emailUpdates(true)}
            onClick={() => {
              onClick(true)
            }}
          >
            <RHeading selected={emailUpdates(true)}> Yes</RHeading>
            <Tick />
          </Right>
        </>
      ) : (
        <NextBtn
          onClick={() => {
            onClick(true)
          }}
        >
          <NHeading selected={emailUpdates(true)}>Next</NHeading>
        </NextBtn>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: inherit;
  cursor: pointer;
  position: relative;
  background-color: white;
  display: flex;
  border-radius: 50px;
  box-shadow: ${Colors.boxShadow};
  z-index: 2;
  border: 1px solid ${Colors.backgroundGrey};
`

const Left = styled.div`
  cursor: pointer;
  padding: 0.5rem 1rem;

  @media ${Devices.mobileM} {
    padding: 0.6rem 1.2rem;
  }

  @media ${Devices.mobileL} {
    padding: 0.7rem 1.4rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;

  transition: all 0.3s;
  border-top: 2px solid
    ${(props) => (props.selected ? Colors.red : Colors.white)};
  border-left: 2px solid
    ${(props) => (props.selected ? Colors.red : Colors.white)};
  border-bottom: 2px solid
    ${(props) => (props.selected ? Colors.red : Colors.white)};
  background-color: ${(props) => (props.selected ? Colors.paleRed : "white")};

  svg {
    .a {
      transition: all 0.3s;
      stroke: ${(props) => (props.selected ? Colors.red : Colors.black)};
    }
  }

  @media ${Devices.laptop} {
    transition: all 0.2s ease-out;
    &:hover {
      background: ${Colors.paleRed};
      border-color: ${Colors.paleRed};
    }
  }
`

const Border = styled.div`
  z-index: 200;
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 50%;
  background-color: ${(props) =>
    props.selected === null
      ? Colors.backgroundGrey
      : props.selected === true
      ? Colors.green
      : props.selected === false
      ? Colors.red
      : Colors.backgroundGrey};
`

const Right = styled.div`
  cursor: pointer;
  padding: 0.5rem 1rem;

  @media ${Devices.mobileM} {
    padding: 0.6rem 1.2rem;
  }

  @media ${Devices.mobileL} {
    padding: 0.7rem 1.4rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;

  transition: all 0.3s;
  border-top: 2px solid
    ${(props) => (props.selected ? Colors.green : Colors.white)};
  border-right: 2px solid
    ${(props) => (props.selected ? Colors.green : Colors.white)};
  border-bottom: 2px solid
    ${(props) => (props.selected ? Colors.green : Colors.white)};
  background-color: ${(props) => (props.selected ? Colors.paleGreen : "white")};

  svg {
    .a {
      transition: all 0.3s;
      stroke: ${(props) => (props.selected ? Colors.green : Colors.black)};
    }
  }

  @media ${Devices.laptop} {
    transition: all 0.2s ease-out;
    &:hover {
      background: ${Colors.paleGreen};
      border-color: ${Colors.paleGreen};
    }
  }
`

const LHeading = styled(H6)`
  cursor: pointer;
  margin-left: 1.25rem;
  transition: all 0.3s;
  color: ${(props) => (props.selected ? Colors.red : Colors.black)};

  /* @media ${Devices.mobileL} {
    margin-right: 1.04rem;
  }

  @media ${Devices.mobileM} {
    margin-right: 0.96rem;
  }

  @media ${Devices.mobileS} {
    margin-right: 0.8rem;
  } */
`

const RHeading = styled(H6)`
  cursor: pointer;
  margin-right: 1.25rem;

  /* @media ${Devices.mobileL} {
    margin-right: 1.04rem;
  }

  @media ${Devices.mobileM} {
    margin-right: 0.96rem;
  }

  @media ${Devices.mobileS} {
    margin-right: 0.8rem;
  } */
  transition: all 0.3s;
  color: ${(props) => (props.selected ? Colors.green : Colors.black)};
`

const LeftText = styled.div``

const RightText = styled.div``

const NextBtn = styled.div`
  cursor: pointer;
  padding: 0.5rem 2rem;

  @media ${Devices.mobileM} {
    padding: 0.6rem 2rem;
  }

  @media ${Devices.mobileL} {
    padding: 0.7rem 2rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;

  transition: all 0.3s;
  border-top: 2px solid
    ${(props) => (props.selected ? Colors.red : Colors.white)};
  border-left: 2px solid
    ${(props) => (props.selected ? Colors.red : Colors.white)};
  border-bottom: 2px solid
    ${(props) => (props.selected ? Colors.red : Colors.white)};
  background-color: ${(props) => (props.selected ? Colors.paleGreen : "white")};

  @media ${Devices.laptop} {
    transition: all 0.2s ease-out;
    &:hover {
      background: ${Colors.paleGreen};
      border-color: ${Colors.paleGreen};
    }
  }
`

const NHeading = styled(H6)`
  cursor: pointer;
  /* margin-right: 1.25rem; */

  transition: all 0.3s;
  color: ${(props) => (props.selected ? Colors.green : Colors.black)};
`

export default YesNoToggle
