import { SET_ONBOARDING_STATUS } from './ActionTypes';
import axios from 'axios';

const Actions = {
  setOnboardingStatus: data => async dispatch => {
    dispatch({
      type: SET_ONBOARDING_STATUS,
      payload: data
    });
  },
  subscribe: data => async dispatch => {
    let sub = await axios.post(`${process.env.REACT_APP_API_URL}/sub`, data);
  }
};

export default Actions;
