export default {
  black: '#000000',
  backgroundGrey: '#F4F6F8',
  altGrey: '#ECEFF2',
  grey: '#57545B',
  red: `#D53516`,
  paleRed: `#FDF5F3`,
  yelow: `#F9CD01`,
  orange: `#D58C16`,
  lightGrey: `#A5AAAD`,
  green: `#5F9422`,
  paleGreen: `#F7FAF5`,
  purple: `#8D4896`,
  maroon: `#C83685`,
  white: `#FFFFFF`,
  blue: `#7BC7EF`,
  turquoise: `#009DA5`,
  darkTurquoise: '#0A8189',
  cream: '#F8F8F6',
  boxShadow: `0px 3px 6px rgba(0,0,0,0.05)`
};
