import "react-app-polyfill/ie11";
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import App from "./containers/app/App";
import RootReducer from "./reducers/RootReducer";
import history from "./history";
import * as serviceWorker from "./serviceWorker";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import persist from "@redux-offline/redux-offline/lib/defaults/persist";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

offlineConfig.persistOptions = {
  blacklist: ["router"],
};

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const store = createStore(
  RootReducer(history),
  composeEnhancer(
    applyMiddleware(routerMiddleware(history), thunk),
    offline(offlineConfig)
  )
);

// Uncomment if you want to purge the redux offline store

// const persistOptions = {};
// persist(store, persistOptions).purge();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
