import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import AppReducer from "./AppReducer";
import OnboardingReducer from "./OnboardingReducer";
import ChecklistReducer from "./ChecklistReducer";
import SavedChecklistsReducer from "./SavedChecklistsReducer";
import NewsReducer from "./NewsReducer";
import ArticleReducer from "./ArticleReducer";
import HelpCentreReducer from "./HelpCentreReducer";
import ExpertsReducer from "./ExpertsReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app: AppReducer,
    onboarding: OnboardingReducer,
    checklist: ChecklistReducer,
    savedChecklists: SavedChecklistsReducer,
    news: NewsReducer,
    article: ArticleReducer,
    helpCentre: HelpCentreReducer,
    experts: ExpertsReducer
  });
