import {
  SET_APP,
  SET_CHECKLIST,
  ADD_CHECKLIST,
  REMOVE_CHECKLIST,
  UPDATE_CHECKLIST,
  SET_QUESTION_ANSWERED,
  SET_SECTION_COMPLETED,
  SET_TOOLTIP_VIEWED
} from "./ActionTypes";
import axios from "axios";

const Actions = {
  loadChecklists: (data) => async (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/checklist`).then((res) => {
      let checklistsArray = res.data.map((checklist) => {
        return {
          id: checklist.id,
          slug: checklist.slug,
          title: checklist.title.rendered,
          image: checklist.acf.image,
          sections: checklist.acf.sections
        };
      });

      let payloadObject = {
        loaded: true,
        checklists: checklistsArray
      };

      dispatch({
        type: SET_APP,
        payload: payloadObject
      });
    });
  },

  setCurrentChecklist: (checklist) => async (dispatch) => {
    dispatch({
      type: SET_CHECKLIST,
      payload: checklist
    });
  },
  loadNewChecklist: (id) => async (dispatch) => {
    dispatch({
      type: SET_CHECKLIST,
      payload: {}
    });
    let checklist = await axios.get(`${process.env.REACT_APP_API_URL}/checklist/${id}`);
    checklist = checklist.data;
    dispatch({
      type: SET_CHECKLIST,
      payload: {
        id: checklist.id,
        slug: checklist.slug,
        title: checklist.title.rendered,
        blurb: checklist.acf.blurb,
        image: checklist.acf.image,
        sections: checklist.acf.sections
      }
    });
  },
  setQuestionAnswered: (data) => async (dispatch) => {
    dispatch({
      type: SET_QUESTION_ANSWERED,
      payload: data.answered
    });
  },
  loadSavedChecklist: (checklist) => async (dispatch) => {
    dispatch({
      type: SET_CHECKLIST,
      payload: { ...checklist }
    });
  },
  setSectionCompleted: (data) => async (dispatch) => {
    dispatch({
      type: SET_SECTION_COMPLETED,
      payload: data.completed
    });
  },
  setTooltipViewed: (data) => async (dispatch) => {
    dispatch({
      type: SET_TOOLTIP_VIEWED,
      payload: data.tooltipViewed
    });
  },
  startChecklist: (data) => async (dispatch) => {
    dispatch({
      type: ADD_CHECKLIST,
      payload: data
    });
  },
  removeChecklist: (id) => async (dispatch) => {
    dispatch({
      type: REMOVE_CHECKLIST,
      payload: id
    });
  },
  updateChecklist: (checklist) => async (dispatch) => {
    dispatch({
      type: UPDATE_CHECKLIST,
      payload: checklist
    });
  }
};

export default Actions;
