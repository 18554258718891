import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';
import Colors from '../../../consts/Colors';
import StylePrefabs from '../../../consts/StylePrefabs';
import ExpertsActions from '../../../actions/ExpertActions';
import AppActions from '../../../actions/AppActions';
import ExpertCard from '../../../components/expert-card/ExpertCard';
import Devices from '../../../consts/Devices';

import H1 from '../../../components/text-elements/heading/H1';
import H5 from '../../../components/text-elements/heading/H5';
import B1 from '../../../components/text-elements/body/B1';

const ExpertsView = ({ loadExperts, experts, setApp, telephone, platform }) => {
  useEffect(() => {
    loadExperts();
    setApp({
      showBlobs: true,
      showBackButton: true,
      backButton: {
        path: `/`,
        text: null
      }
    });
  }, []);
  return (
    <Wrapper>
      <Title>Contact our legal experts</Title>
      <Inner>
        {experts.map((expert, i) => {
          return (
            <ExpertCard
              expert={expert}
              key={i}
              telephone={(number) => {
                telephone(number, platform);
              }}
            />
          );
        })}
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper};

  a {
    color: inherit;
    text-decoration: none;
  }
`;
const Title = styled(H1)`
  color: ${Colors.black};
  text-align: center;
  margin-bottom: 1.722rem;
  @media ${Devices.laptop} {
    margin: 0 auto;
    text-align: left;
    margin-bottom: 0;
  }
`;

const Inner = styled.div`
  padding: 0rem 1rem;
  border-radius: 20px 20px 0 0;

  @media ${Devices.laptop} {
    padding: 3.6875rem 0rem;
  }

  h3 {
    max-width: 105px;
    @media ${Devices.laptop} {
      max-width: none;
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    experts: state.experts.experts,
    platform: state.app.platform
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadExperts: () => {
      dispatch(ExpertsActions.loadExperts());
    },
    setApp: (data) => {
      dispatch(AppActions.setApp(data));
    },
    telephone: (number, platform) => {
      dispatch(AppActions.telephone(number, platform));
    }
  };
};

const connectedExpertsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpertsView);

export default withRouter(connectedExpertsView);
