import { SET_APP, OPEN_SHARE_SHEET, OPEN_TEL_MODAL } from './ActionTypes';

const Actions = {
  setApp: data => async dispatch => {
    dispatch({
      type: SET_APP,
      payload: data
    });
  },
  openShareSheet: (url = window.location.href) => async dispatch => {
    if (window.navigator.share) {
      window.navigator.share({
        title: document.title,
        url: url
      });
    } else {
      dispatch({
        type: OPEN_SHARE_SHEET,
        payload: url
      });
    }
  },
  telephone: (number, platform) => async dispatch => {
    if (platform !== 'Desktop') {
      window.location.href = `tel:${number}`;
    } else {
      dispatch({
        type: OPEN_TEL_MODAL,
        payload: number
      });
    }
  }
};

export default Actions;
