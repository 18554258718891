import { SET_ONBOARDING_STATUS } from '../actions/ActionTypes';

const initialState = {
  onboardingPassed: false,
  name: '',
  email: '',
  company: '',
  jobTitle: '',
  sector: '',
  emailUpdates: null,
  gdprAccepted: false
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_ONBOARDING_STATUS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
