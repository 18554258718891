import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { withRouter } from 'react-router-dom';
import AppActions from '../../actions/AppActions';
import OnboardingActions from '../../actions/OnboardingActions';
import StylePrefabs from '../../consts/StylePrefabs';
import styled from 'styled-components/macro';
import Devices from '../../consts/Devices';
import H1 from '../../components/text-elements/heading/H1';
import B2 from '../../components/text-elements/body/B2';
import Error from '../../components/text-elements/error/Error';
import TextInput from '../../components/text-input/TextInput';
import Next from '../../components/ctas/next/Next';
import YesNoToggle from '../../components/yes-no-toggle/YesNoToggle';
import OnboardingHeading from '../../components/onboarding-heading/OnboardingHeading';

class OnboardingRegistrationView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nameValue: this.props.onboarding.name,
      emailValue: this.props.onboarding.email,
      companyValue: this.props.onboarding.company,
      jobTitleValue: this.props.onboarding.jobTitle,
      sectorValue: this.props.onboarding.sector,
      error: null,
      emailValid: false,
      nameValid: false,
      companyValid: false,
      yesNoValid: false,
      emailUpdates: null
    };

    this.onInput = this.onInput.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.errorMessageHandling = this.errorMessageHandling.bind(this);
    this.handleYesNoToggleClick = this.handleYesNoToggleClick.bind(this);
    this.checkOnboardingStatus = this.checkOnboardingStatus.bind(this);
  }

  componentDidMount() {
    this.props.setApp({
      hideMenu: true,
      showBlobs: false,
      showBackButton: true,
      backButton: {
        path: `/onboarding/1`,
        text: null
      },
      withinOnboarding: true
    });

    this.checkOnboardingStatus();
  }

  componentWillUnmount() {
    this.props.setApp({
      hideMenu: false,
      showBlobs: true,
      withinOnboarding: false
    });
  }

  checkOnboardingStatus() {
    if (this.props.onboarding.onboardingPassed) {
      history.push('/');
    }
  }

  onInput(value, type) {
    switch (type) {
      case 'name':
        this.setState({ nameValue: value });
        break;
      case 'email':
        this.setState({ emailValue: value });
        break;
      case 'company':
        this.setState({ companyValue: value });
        break;
      case 'job-title':
        this.setState({ jobTitleValue: value });
        break;
      case 'sector':
        this.setState({ sectorValue: value });
        break;
    }
  }

  validateFields() {
    let name = this.state.nameValue;
    let email = this.state.emailValue;
    let company = this.state.companyValue;

    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let emailMatch = email.match(regex);

    if (company === '') {
      this.setState({ companyValid: false }, () => this.errorMessageHandling());
    } else if (company !== '') {
      this.setState({ companyValid: true }, () => this.errorMessageHandling());
    }

    if (emailMatch === null) {
      this.setState({ emailValid: false }, () => this.errorMessageHandling());
    } else if (emailMatch) {
      this.setState({ emailValid: true }, () => this.errorMessageHandling());
    }

    if (name === '') {
      this.setState({ nameValid: false }, () => this.errorMessageHandling());
    } else if (name !== '') {
      this.setState({ nameValid: true }, () => this.errorMessageHandling());
    }
  }

  errorMessageHandling() {
    if (
      this.state.emailValid &&
      this.state.nameValid &&
      this.state.companyValid &&
      this.state.yesNoValid
    ) {
      this.setState({ error: null });
      this.props.setOnboardingStatus({
        name: this.state.nameValue,
        email: this.state.emailValue,
        company: this.state.companyValue,
        jobTitle: this.state.jobTitleValue,
        sector: this.state.sectorValue,
        emailUpdates: this.state.emailUpdates
      });
      history.push('/onboarding/3');
    }
    if (!this.state.yesNoValid) {
      this.setState({
        error: 'Please select whether to subscribe to email updates'
      });
    }
    if (!this.state.companyValid) {
      this.setState({ error: 'Please enter your company name' });
    }
    if (!this.state.emailValid) {
      this.setState({ error: 'Please enter a valid email address' });
    }
    if (!this.state.nameValid) {
      this.setState({ error: 'Please enter your name' });
    }
  }

  handleYesNoToggleClick(val) {
    this.setState({ emailUpdates: val, yesNoValid: true });
  }

  render() {
    return (
      <Wrapper>
        <OnboardingHeading>Your details</OnboardingHeading>
        <RegForm autoComplete="off">
          <TextInput type={'name'} onInput={this.onInput} value={this.state.nameValue} />
          <TextInput type={'email'} onInput={this.onInput} value={this.state.emailValue} />
          <TextInput type={'company'} onInput={this.onInput} value={this.state.companyValue} />
          <TextInput type={'job-title'} onInput={this.onInput} value={this.state.jobTitleValue} />
          <TextInput type={'sector'} onInput={this.onInput} value={this.state.sectorValue} />
        </RegForm>
        <Body>Do you want to receive email updates from us? *</Body>
        <YesNoToggle onClick={this.handleYesNoToggleClick} selected={this.state.emailUpdates} />
        <ErrorStyledComp>{this.state.error}</ErrorStyledComp>
        <GetStartedButtonWrapper>
          <Next onClick={() => this.validateFields()}>Get started</Next>
        </GetStartedButtonWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper};
  ${StylePrefabs.fullHeightMobileWithHeader};
  display: relative;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 4.5rem !important; */
  padding-left: 2rem;
  padding-right: 2rem;

  @media ${Devices.laptop} {
    min-height: 0px;
  }
`;

const RegForm = styled.form`
  width: 100%;
  max-width: 900px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Body = styled(B2)`
  max-width: 80%;
  text-align: center;
  margin-bottom: 1rem;
`;

const ErrorStyledComp = styled(Error)`
  margin: 1rem;
`;

const GetStartedButtonWrapper = styled.div`
  /* position: absolute; */
  margin-bottom: 2rem;
`;

const mapStateToProps = (state) => {
  return {
    onboarding: state.onboarding
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setApp: (data) => {
      dispatch(AppActions.setApp(data));
    },
    setOnboardingStatus: (data) => {
      dispatch(OnboardingActions.setOnboardingStatus(data));
    }
  };
};

const connectedOnboardingRegistrationView = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingRegistrationView);

export default withRouter(connectedOnboardingRegistrationView);
