import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import Typography from "../../../consts/Typography";
import Utils from "../../../consts/Utils";
import Devices from "../../../consts/Devices";

const H1 = ({ children, style, className }) => {
  return (
    <HeadingText style={style} className={className}>
      {children}
    </HeadingText>
  );
};

const Wrapper = styled.div``;

const HeadingText = styled.h1`
  ${Typography.montserratBold};
  font-size: ${Typography.fontSizes.mobile.h1};
  line-height: ${Typography.lineHeights.mobile.h1};
  text-transform: uppercase;

  @media ${Devices.laptop} {
    font-size: ${Typography.fontSizes.desktop.h1};
    line-height: ${Typography.lineHeights.desktop.h1};
  }
`;

export default H1;
