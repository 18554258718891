import Devices from './Devices';
import Colors from './Colors';
import Utils from './Utils';

export default {
  montserratBold: `
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin: 0;
    `,
  montserratRegular: `
    font-family: 'Montserrat', sans-serif;
    font-weight: regular;
    margin: 0;
    `,
  fontSizes: {
    mobile: {
      h1: '22px',
      h2: '20px',
      h3: '18px',
      h4: '16px',
      h5: '14px',
      h6: '12px',
      b1: '16px',
      b2: '14px',
      b3: '12px',
      b4: '10px'
    },

    desktop: {
      h1: '32px',
      h2: '20px',
      h3: '18px',
      h4: '16px',
      h5: '14px',
      h6: '12px',
      b1: '16px',
      b2: '14px',
      b3: '12px',
      b4: '12px'
    }
  },

  lineHeights: {
    mobile: {
      h1: '28px',
      h2: '26px',
      h3: '24px',
      h4: '22px',
      h5: '20px',
      h6: '18px',
      b1: '20px',
      b2: '18px',
      b3: '15px',
      b4: '12px'
    },

    desktop: {
      h1: '38px',
      h2: '26px',
      h3: '24px',
      h4: '22px',
      h5: '20px',
      h6: '18px',
      b1: '22px',
      b2: '18px',
      b3: '16px',
      b4: '12px'
    }
  }
};
