import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import "font-awesome/css/font-awesome.min.css";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import "./Normalize.css";
import "./Typo.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "react-id-swiper/lib/styles/css/swiper.css"
import AppActions from "../../actions/AppActions";
import StylePrefabs from "../../consts/StylePrefabs";
import Home from "../home/Home";
import OnboardingCarouselView from "../onboarding/OnboardingCarouselView";
import OnboardingRegistrationView from "../onboarding/OnboardingRegistrationView";
import GdprView from "../gdpr-view/GdprView";
import ChecklistsView from "../checklists-view/ChecklistsView";
import ChecklistView from "../checklist-view/ChecklistView";
import SectionView from "../section-view/SectionView";
import InfoLibraryView from "../info-libary/info-library-view/InfoLibraryView";
import InfoLibraryContentItem from "../info-libary/info-library-content-item/InfoLibraryContentItem";
import ExpertsView from "../experts/experts-view/ExpertsView";
import ExpertBio from "../experts/expert-bio/ExpertBio";
import HelpCentre from "../help-centre-view/HelpCentreView";
import ReaderView from "../reader/Reader";
import Header from "../../components/header/Header.js";
import ScrollToTop from "../../components/scrolltotop/Scrolltotop";
import SidebarNav from "../../components/sidebar-nav/SidebarNav";
import { persistStore } from "redux-persist";
import { useMediaQuery } from "react-responsive";
import Devices from "../../consts/Devices";
import ShareSheet from "../../components/share-sheet/ShareSheet";
// import InstallBanner from '../../components/install-banner/InstallBanner';
import Back from "../../components/back/Back";
import Colors from "../../consts/Colors";
import Analytics from "../../components/analytics/Analytics";
import TelephoneModal from "../../components/telephone-modal/TelephoneModal";

class App extends React.Component {
  componentDidMount() {
    // this.props.onAppLoad({
    //   loaded: true
    // });
    // let deferredPrompt;
    // window.addEventListener('beforeinstallprompt', e => {
    //   alert('');
    //   // Stash the event so it can be triggered later.
    //   deferredPrompt = e;
    //   // Update UI notify the user they can add to home screen
    //   e.showInstallPromotion();
    // });
  }

  componentWillMount() {
    let OS = this.getOs();
    this.props.setApp({
      platform: OS,
    });
  }

  getOs() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "Desktop";
  }

  render() {
    window.localStorage.removeItem("iosbanner");
    if (!this.props.hydrated) {
      return <span />;
    }

    if (
      !this.props.onboarding.onboardingPassed &&
      this.props.location.pathname !== "/onboarding/1" &&
      this.props.location.pathname !== "/onboarding/2" &&
      this.props.location.pathname !== "/onboarding/3"
    ) {
      this.props.setApp({
        forwardingPath: window.location.pathname,
      });
      return <Redirect to="/onboarding/1" />;
    }

    return (
      <ScrollToTop>
        {this.props.app.loaded ? (
          <AppWrapper>
            <View>
              <SidebarNav
                showBackButton={this.props.app.showBackButton}
                onBoarded={this.props.onboarding.onboardingPassed}
                name={this.props.name}
              />
              <ViewInner
                showBackButton={this.props.app.showBackButton}
                withinOnboarding={this.props.app.withinOnboarding}
              >
                {this.props.app.showHeader ? (
                  <Header withinOnboarding={this.props.app.withinOnboarding} />
                ) : null}
                <Route
                  exact
                  path="/"
                  render={() => {
                    return <Home />;
                  }}
                />
                <Route
                  exact
                  path="/onboarding/1"
                  render={() => {
                    return <OnboardingCarouselView />;
                  }}
                />
                <Route
                  exact
                  path="/onboarding/2"
                  render={() => {
                    return <OnboardingRegistrationView />;
                  }}
                />
                <Route
                  exact
                  path="/onboarding/3"
                  render={() => {
                    return <GdprView />;
                  }}
                />
                <Route
                  exact
                  path="/checklists"
                  render={() => {
                    return <ChecklistsView />;
                  }}
                />
                <Route
                  exact
                  path="/checklist/:type/:checklistId"
                  render={() => {
                    return <ChecklistView />;
                  }}
                />
                <Route
                  exact
                  path="/checklist/saved/:checklistId/:sectionId"
                  render={() => {
                    return <SectionView />;
                  }}
                />
                <Route
                  exact
                  path="/info-library"
                  render={() => {
                    return <InfoLibraryView />;
                  }}
                />
                <Route
                  exact
                  path="/info-library/:contentId"
                  render={() => {
                    return <InfoLibraryContentItem />;
                  }}
                />
                <Route
                  exact
                  path="/experts"
                  render={() => {
                    return <ExpertsView />;
                  }}
                />
                <Route
                  exact
                  path="/help-centre"
                  render={() => {
                    return <HelpCentre />;
                  }}
                />
                <Route
                  exact
                  path="/experts/:expertId"
                  render={() => {
                    return <ExpertBio />;
                  }}
                />
                <Route
                  exact
                  path="/reader/:text"
                  render={() => {
                    return <ReaderView />;
                  }}
                />

                <ShareSheet
                  url={this.props.app.shareUrl}
                  close={() => {
                    this.props.openShareSheet({
                      url: null,
                    });
                  }}
                />
                {/* {this.props.app.showIosBanner && (
                  <InstallBanner
                    hideBanner={() => {
                      this.props.setApp({
                        showIosBanner: false
                      });
                    }}
                  />
                )} */}
              </ViewInner>
            </View>
            <Analytics
              url={window.location.pathname + window.location.search}
            />
            <TelephoneModal
              number={this.props.telModalNumber}
              close={() => {
                this.props.setApp({
                  telModalNumber: null,
                });
              }}
            />
          </AppWrapper>
        ) : null}
      </ScrollToTop>
    );
  }
}

const AppWrapper = styled.div`
  ${StylePrefabs.appWrapper};
  @media ${Devices.laptop} {
    /* min-height: 780px; */
    overflow-y: scroll;
  }
`;

const View = styled.div`
  @media ${Devices.laptop} {
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
  }
`;

const ViewInner = styled.div`
  width: 100%;
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
  @media ${Devices.laptop} {
    flex: 4;
    /* padding: 4rem 0rem 0; */
    padding: 0rem 0rem 0;
    position: relative;
    ${(props) =>
      props.showBackButton &&
      `
    // min-height: calc(100vh - 2rem);
    background: ${Colors.backgroundGrey};
    border-radius: 10px;
    // margin: 1rem;
    right: 0rem;
    // border-radius: 10px;
    // border: 1rem solid #fff;
    min-height: calc(100vh - 2rem);
    overflow: hidden;
    margin: 1rem;
  `}

    ${(props) =>
      props.withinOnboarding &&
      ` 
    padding: 0rem;
    background: ${Colors.backgroundGrey};
    border-radius: 10px;
    right: 0rem;
    min-height: calc(100vh - 2rem);
    overflow: hidden;
    margin: 1rem;
  `}
  }
`;

const mapStateToProps = (state) => {
  return {
    app: state.app,
    hydrated: state.app.hydrated,
    onboarding: state.onboarding,
    name: state.onboarding.name,
    showHeader: state.app.showHeader,
    telModalNumber: state.app.telModalNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setApp: (data) => {
      dispatch(AppActions.setApp(data));
    },
    openShareSheet: (url) => {
      dispatch(AppActions.openShareSheet(url));
    },
  };
};

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(connectedApp);
