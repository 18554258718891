import { LOADED_CATEGORY, LOADED_ARTICLE, CLEAR_ARTICLE } from './ActionTypes';
import axios from 'axios';

const Actions = {
  loadPosts: data => async dispatch => {
    let categories = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);

    categories = categories.data.filter(art => art.slug !== 'uncategorized');

    categories.map(async category => {
      let items = await axios.get(
        `${process.env.REACT_APP_API_URL}/posts?categories=${category.id}&t=?${+new Date()}`
      );

      dispatch({
        type: LOADED_CATEGORY,
        payload: {
          category: category.slug,
          items: items.data
        }
      });
    });
  },
  loadPost: id => async dispatch => {
    dispatch({
      type: CLEAR_ARTICLE
    });
    let article = await axios.get(`${process.env.REACT_APP_API_URL}/posts?slug=${id}`);

    dispatch({
      type: LOADED_ARTICLE,
      payload: article.data[0]
    });
  }
};

export default Actions;
