import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import H3 from '../text-elements/heading/H3';
import { ReactComponent as Phone } from '../../assets/svg/phone.svg';
import Colors from '../../consts/Colors';
import { ReactComponent as Cross } from '../../assets/svg/cross.svg';

const TelephoneModal = ({ number, close }) => {
  return (
    <Wrapper onClick={close} open={number !== null}>
      <Inner>
        <Close>
          <Cross />
        </Close>
        <Phone />
        <H3>{number}</H3>
      </Inner>
    </Wrapper>
  );
};

const Close = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${props => (props.open ? 0 : `-10000px`)};
  right: ${props => (props.open ? 0 : 'auto')};
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 100;

  svg {
    path {
      fill: ${Colors.turquoise};
    }
    margin-bottom: 1rem;
  }
`;

const Inner = styled.div`
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
`;

export default TelephoneModal;
