import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import H5 from '../text-elements/heading/H5';
import H4 from '../text-elements/heading/H4';
import B3 from '../text-elements/body/B3';
import Colors from '../../consts/Colors';
import Devices from '../../consts/Devices';

import { ReactComponent as Tick } from '../../assets/svg/tick.svg';
import { ReactComponent as Dots } from '../../assets/svg/dots.svg';

const ChecklistSection = ({ i, section, onClick }) => {
  let numberAnswered = section.questions.filter((question) => question.answered == true).length;
  let numberSeen = section.questions.filter((question) => question.tooltipViewed === true).length;
  let complete = numberAnswered == section.questions.length;
  let semiComplete = (numberAnswered > 0 && !complete) || (numberSeen > 0 && !complete);
  return (
    <Wrapper onClick={() => onClick(i)} complete={complete} semiComplete={semiComplete}>
      <Number complete={complete} semiComplete={semiComplete}>
        <H4>{i + 1}.</H4>
      </Number>
      <Title>
        <H5>{section.section_title}</H5>
      </Title>
      <Progress complete={complete} semiComplete={semiComplete}>
        <div>
          <B3>
            {numberAnswered} / {section.questions.length}
          </B3>
          {semiComplete && <Dots style={{ position: 'relative', top: 5, paddingLeft: 4 }} />}
          {complete && <Tick style={{ position: 'relative', top: 1, paddingLeft: 4 }} />}
        </div>
      </Progress>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  background: #fff;
  padding: 1rem;
  border-radius: 5px;
  margin: 1px 0;
  justify-content: space-between;
  ${(props) =>
    props.complete &&
    `
    background: #E1F4CB;
    h5{
      color: ${Colors.green}!important;
    } 
  `}
  ${(props) =>
    props.semiComplete &&
    `
   
    transform: scale(1.03);
    margin: 4px 0;
    h5{
      color: ${Colors.black}!important;
    } 
    @media ${Devices.laptop}{
      transform: scale(1.05);
    }
  `}

  @media ${Devices.laptop}{
    margin: 5px 0;
    transition: all 0.2s ease-out;
    &:hover {
      box-shadow: ${Colors.boxShadow};
    }
  }
`;

const Number = styled.div`
  flex: 1.1;
  background: ${Colors.lightGrey};
  max-width: 1.8rem;
  height: 1.875rem;
  position: relative;
  border-radius: 5px;
  margin-right: 15px;
  ${(props) =>
    props.complete &&
    `
    background: ${Colors.green};
  `}
  ${(props) =>
    props.semiComplete &&
    `
    background: ${Colors.orange};
  `}

  h4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
  }
`;

const Title = styled.div`
  h5 {
    line-height: 28px;
    color: ${Colors.grey};
  }
  flex: 10;
`;

const Progress = styled.div`
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div {
    background: ${Colors.lightGrey};
    border-radius: 5px;
    padding: 4px 8px;
    display: flex;
  }
  span {
    color: #fff;
    display: block;
    line-height: 1;
  }

  ${(props) =>
    props.complete &&
    `
    > div {
      background: ${Colors.green};
    }
  `}
  ${(props) =>
    props.semiComplete &&
    `
    > div {
      background: ${Colors.orange};
    }
  `}
`;

export default ChecklistSection;
