import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import Slider from "react-slick"
import { Redirect } from "react-router-dom"
import Colors from "../../../../consts/Colors"
import { B1 } from "../../../text-elements/index"
import OnboardingCarouselNext from "../../../ctas/onboarding-carousel-next/OnboardingCarouselNext"
import Devices from "../../../../consts/Devices"
import CarouselDots from "../../carousel-dots/CarouselDots"
import OnboardingHeading from "../../../onboarding-heading/OnboardingHeading"
import Utils from "../../../../consts/Utils"
import Logo from "../../../../assets/images/logo.png"
import Logo2x from "../../../../assets/images/logo@2x.png"
import AddToHomeScreenButton from "../../../add-to-home-screen-button/AddToHomeScreenButton"
import OnboardingCarouselExample from "../../../../assets/images/onboarding-carousel-example.png"
import OnboardingCarouselExample2x from "../../../../assets/images/onboarding-carousel-example2x.png"
import StartingChecklistVideo from "../../../../assets/video/animated-caro-starting-checklist.mp4"

const DesktopOnboardingCarousel = ({ controls }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [redirect, setRedirect] = useState(false)
  const sliderEl = useRef(null)

  const settings = {
    className: "center",
    dots: false,
    centerMode: true,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 1,
    accessibility: true,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    afterChange: (index) => setCurrentIndex(index),
  }

  let carouselItems = [
    {
      src: Utils.isRetina(Logo, Logo2x),
      stage: null,
      header: "Welcome",
      body:
        "This application provides information on your health and safety obligations and guidance to help you deal with a health and safety incident",
    },
    {
      src: StartingChecklistVideo,
      stage: null,
      header: "Start a checklist",
      body:
        "Our checklists help make sure you have everything under control, when you need it.",
      video: true,
    },
  ]

  const next = () => {
    if (currentIndex + 1 === sliderEl.current.props.children.length) {
      setRedirect(true)
    } else {
      sliderEl.current.slickNext()
    }
  }

  return (
    <Wrapper>
      <>{redirect ? <Redirect push to="/onboarding/2" /> : null}</>
      <Slider ref={sliderEl} {...settings}>
        {carouselItems.map((carouselItem, index) => {
          return (
            <CarouselInnerWrapper key={index}>
              {carouselItem.video ? (
                <CarouselVideo
                  onLoadedData={(el) => {
                    el.currentTarget.play()
                  }}
                  key={index}
                  video={true}
                  index={index}
                  autoplay="autoplay"
                  playsinline
                  loop
                  muted
                >
                  <source src={StartingChecklistVideo} type="video/mp4" />
                </CarouselVideo>
              ) : (
                <CarouselImage
                  src={carouselItem.src}
                  key={index}
                  index={index}
                />
              )}

              <TextWrapper>
                <OnboardingHeading className={"onboarding-carousel-heading"}>
                  {carouselItem.header}
                </OnboardingHeading>
                <Body>{carouselItem.body}</Body>
              </TextWrapper>
            </CarouselInnerWrapper>
          )
        })}
      </Slider>
      <BottomWrapper>
        <Dots
          items={carouselItems}
          currentIndex={currentIndex}
          color={Colors.turquoise}
          backgroundColor={Colors.white}
        />
        <CTA onClick={() => next()}>Next</CTA>
      </BottomWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: relative;

  .slick-slider {
    .slick-list {
      .slick-track {
        display: flex;
        align-items: center;
        justify-content: center;
        .slick-slide {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          transition: opacity 2.5s;
          opacity: 0;
        }

        .slick-current {
          opacity: 1;
          transition: opacity 2.5s;
        }
      }
    }
  }
`

const CarouselInnerWrapper = styled.div`
  min-height: 400px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none !important;
`

const Body = styled(B1)`
  text-align: center;
  max-width: 240px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  .onboarding-carousel-heading {
    max-width: 100%;
  }
`

const Bar = styled.div`
  border-radius: 5px;
  width: 12px;
  border: 1px solid ${Colors.turquoise};
  margin-bottom: 1rem;
  @media ${Devices.mobileM} {
    margin-bottom: 1.5rem;
  }
`

const CarouselVideo = styled.video`
  max-height: 270px;
  border-radius: 10px;

  @media ${Devices.mobileTall} {
    max-height: 375px;
  }

  @media ${Devices.laptop} {
    max-height: 425px;
  }
`

const CarouselImage = styled.img`
  max-width: ${(props) =>
    props.index === 0
      ? `130px`
      : props.index === 1
      ? "100%"
      : `250px`} !important;
  max-height: ${(props) => (props.index === 1 ? `220px` : `200px`)};

  @media ${Devices.mobileTall} {
    max-height: ${(props) => (props.index === 1 ? `300px` : `200px`)};
  }
`

const BottomWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  @media ${Devices.laptop} {
    padding-left: 0rem;
  }
`

const Dots = styled(CarouselDots)`
  @media ${Devices.laptop} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
  }
`

const CTA = styled(OnboardingCarouselNext)`
  @media ${Devices.laptop} {
    right: 15px;
    bottom: 0px;
    position: fixed;
    /* transform: translateX(-50%); */
  }
`

export default DesktopOnboardingCarousel
