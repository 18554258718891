import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import Typography from "../../../consts/Typography";
import Utils from "../../../consts/Utils";
import Devices from "../../../consts/Devices";

const H2 = ({ children, style, className }) => {
  return (
    <HeadingText style={style} className={className}>
      {children}
    </HeadingText>
  );
};

const HeadingText = styled.h2`
  ${Typography.montserratBold};
  font-size: ${Typography.fontSizes.mobile.h2};
  line-height: ${Typography.lineHeights.mobile.h2};
  text-transform: uppercase;

  @media ${Devices.laptop} {
    font-size: ${Typography.fontSizes.desktop.h2};
    line-height: ${Typography.lineHeights.desktop.h2};
  }
`;

export default H2;
