import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components/macro"
import { Link, withRouter } from "react-router-dom"
import AppActions from "../../actions/AppActions"
import StylePrefabs from "../../consts/StylePrefabs"
import Typography from "../../consts/Typography"
import Colors from "../../consts/Colors"
import Utlis from "../../consts/Utils"
import Card from "../../components/card/Card"
import Devices from "../../consts/Devices"
import history from "../../history"

import Checklist from "../../assets/images/icon-checklist.png"
import Checklist2x from "../../assets/images/icon-checklist@2x.png"

import Experts from "../../assets/images/icon-experts.png"
import Experts2x from "../../assets/images/icon-experts@2x.png"

import Library from "../../assets/images/icon-library.png"
import Library2x from "../../assets/images/icon-library@2x.png"

import FAQS from "../../assets/images/icon-faqs.png"
import FAQS2x from "../../assets/images/icon-faqs@2x.png"

import Multi from "../../assets/images/icon-multi.png"
import Multi2x from "../../assets/images/icon-multi.png"

import { ReactComponent as ChecklistIcon } from "../../assets/svg/checklist-icon.svg"
import { ReactComponent as ExpertIcon } from "../../assets/svg/experts-icon.svg"
import { ReactComponent as InfoIcon } from "../../assets/svg/info-icon.svg"
import { ReactComponent as FAQIcon } from "../../assets/svg/faq-icon.svg"
import { ReactComponent as StatementIcon } from "../../assets/svg/statement-icon.svg"

import Next from "../../components/ctas/next/Next"

import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  B1,
  B2,
  B3,
} from "../../components/text-elements"

class Home extends React.Component {
  componentDidMount() {
    this.props.setApp({
      showBackButton: false,
      showBlobs: true,
    })
  }
  render() {
    return (
      <HomeWrapper>
        <Hello>
          <B1>Hello, {this.props.name.split(" ")[0]}</B1>
        </Hello>

        <HomeCards>
          <WelcomeMessage>
            <WelcomeText>
              Health & Safety <a>by Brodies</a>
            </WelcomeText>
            <NextBtn
              onClick={() => {
                history.push("/checklists")
              }}
            >
              Start a checklist
            </NextBtn>
          </WelcomeMessage>
          <Card
            type="HOME"
            index={1}
            svgIcon={<ChecklistIcon />}
            subTitle={"start a"}
            title={"Checklist"}
            to="/checklists"
            icon={Utlis.isRetina(Multi, Multi2x)}
            backgroundImg={Utlis.isRetina(Checklist, Checklist2x)}
            barColor={"#009DA5"}
          />
          <Card
            type="HOME"
            index={2}
            svgIcon={<ExpertIcon />}
            subTitle={"contact"}
            title={"Our Experts"}
            to="/experts"
            icon={Utlis.isRetina(FAQS, FAQS2x)}
            barColor={"#845692"}
          />
          <Card
            type="HOME-FULL"
            index={2}
            svgIcon={<StatementIcon />}
            subTitle={"Giving a"}
            title={"HSE / Police Statement"}
            to="/reader/statement"
            icon={Utlis.isRetina(Experts, Experts2x)}
            barColor={"#860412"}
          />
          <Card
            type="HOME"
            index={3}
            subTitle={"Info"}
            title={"Library"}
            to="/info-library"
            icon={Utlis.isRetina(Library, Library2x)}
            barColor={"#88D0FF"}
            svgIcon={<InfoIcon />}
          />
          <Card
            type="HOME"
            index={4}
            subTitle={"our"}
            title={"FAQS"}
            to="/help-centre"
            icon={Utlis.isRetina(Checklist, Checklist2x)}
            barColor={"#CD3F84"}
            svgIcon={<FAQIcon />}
          />
        </HomeCards>
      </HomeWrapper>
    )
  }
}

const HomeWrapper = styled.div`
  ${StylePrefabs.pageWrapper};
  ${StylePrefabs.fullHeightMobileWithHeader};
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 4rem;

  @media ${Devices.laptop} {
    ${StylePrefabs.fullHeightDesktop};
  }
`

const HomeCards = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    margin-bottom: 10px;
    width: 49%;
  }

  @media ${Devices.laptop} {
    visibility: hidden;
    height: 0px;
    overflow: hidden;
  }
`

const WelcomeMessage = styled.div`
  display: none;
  @media ${Devices.laptop} {
    display: flex;
    flex-direction: column;
    width: auto !important;
    justify-content: center;
    align-items: center;
    visibility: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: ease-out;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-timing-function: ease-out;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const NextBtn = styled(Next)`
  margin-top: 1.5rem;
  width: 201px;

  @media ${Devices.laptop} {
    width: 203px;
    border-style: solid;
    border-width: 1px;
    border-color: ${Colors.turquoise};
  }
`

const WelcomeText = styled(H1)`
  letter-spacing: 0.5px;
  text-align: center;
  min-width: 550px;
  a {
    color: ${Colors.turquoise};
  }
`

const Hello = styled.div`
  background: rgba(0, 0, 0, 0.03);
  margin: 0 auto 30px;
  border-radius: 99999em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
  padding: 7px 20px;
  display: flex;
  span {
    color: ${Colors.black};
    font-weight: 500;
  }

  @media ${Devices.laptop} {
    display: none;
  }
`

const mapStateToProps = state => {
  return {
    app: state.app,
    name: state.onboarding.name,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApp: data => {
      dispatch(AppActions.setApp(data))
    },
  }
}

const connectedHome = connect(mapStateToProps, mapDispatchToProps)(Home)

export default withRouter(connectedHome)
