import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";
import Devices from "../../consts/Devices";
import StylePrefabs from "../../consts/StylePrefabs";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch } from "react-redux";

import AppActions from "../../actions/AppActions";

const Reader = ({ text }) => {
  const dispatch = useDispatch();
  const _root = useRef();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [noPages, setNoPages] = useState(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    dispatch(
      AppActions.setApp({
        showBackButton: true,
        showBlobs: true,
        backButton: {
          path: `/`,
          text: null,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (_root.current) {
      let bb = _root.current.getBoundingClientRect();
      setWidth(bb.width);
    }
  }, [_root]);

  let path =
    "https://brodieshs.wpenginepowered.com/wp-content/uploads/2024/05/Health_and_Safety_HANDYGUIDE_Statement_Taking_2024-1.pdf";
  switch (text) {
    case "statement":
      path = "";
      break;
  }
  return (
    <Wrapper ref={_root}>
      <div style={{ overflow: "scroll", height: "100%" }}>
        <Document
          file={path}
          onLoadError={console.error}
          onLoadSuccess={(document) => {
            const { numPages } = document;
            setNoPages(numPages);
          }}
          className="doc"
        >
          {Array.from(new Array(noPages), (el, index) => {
            return (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={width}
              />
            );
          })}
        </Document>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper};
  padding-bottom: 4rem;
  margin: 0 auto;
  min-height: 100vh;
  @media ${Devices.laptop} {
    max-width: 1940px;
  }

  .doc canvas {
    margin: 0 auto;
  }

  .doc {
    overflow: hidden;
  }
`;

export default Reader;
