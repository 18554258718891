import React, { useRef, useState, Component } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import H2 from '../text-elements/heading/H2';
import B2 from '../text-elements/body/B2';
import Devices from '../../consts/Devices';
import CloseButton from '../circle-buttons/close-circle-button/CloseCircleButton';

const Modal = ({
  checklistName,
  closeModal,
  component,
  hideCloseButton,
  className,
  modalWidth
}) => {
  return (
    <Wrapper onClick={closeModal}>
      <ModalOuter className={className}>
        <ModalInner modalWidth={modalWidth}>
          <>
            {hideCloseButton ? null : (
              <CloseButtonWrapper onClick={closeModal}>
                <CloseButton />
              </CloseButtonWrapper>
            )}
          </>
          {component}
        </ModalInner>
      </ModalOuter>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 1s;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ModalOuter = styled.div`
  position: absolute;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  box-shadow: ${Colors.boxShadow};
`;

const ModalInner = styled.div`
  background-color: ${Colors.white};
  border-radius: 10px;
  min-width: 100%;
  /* max-width: 415px; */
  padding: 2.5rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${Devices.tablet} {
    min-width: ${(props) => (props.modalWidth ? `${props.modalWidth}px` : '325px')};
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

export default Modal;
