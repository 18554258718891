import {
  SET_CHECKLIST,
  SET_QUESTION_ANSWERED,
  SET_SECTION_COMPLETED,
  SET_TOOLTIP_VIEWED
} from "../actions/ActionTypes";

const initialState = {};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_CHECKLIST:
      return {
        ...action.payload
      };
    case SET_QUESTION_ANSWERED:
      return {
        ...state,
        ...action.payload
      };
    case SET_SECTION_COMPLETED:
      return {
        ...state,
        ...action.payload
      };
    case SET_TOOLTIP_VIEWED:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
