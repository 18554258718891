import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import H5 from '../../text-elements/heading/H5';
import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow-right.svg';
import Colors from '../../../consts/Colors';

const TextOnly = ({ onClick, children, disabled }) => {
  return (
    <Control disabled={disabled} onClick={disabled ? null : () => onClick()}>
      <Heading disabled={disabled}>{children}</Heading>
      <Inner disabled={disabled}>{/* <ArrowRight /> */}</Inner>
    </Control>
  );
};

const Control = styled.div`
  position: relative;
  padding: 5px 50px;
  transition: all, 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${Colors.turquoise};
    width: 20px;
    height: 9px;
  }
`;

const Heading = styled(H5)`
  text-align: center;
  margin-right: 10px;
  color: ${(props) => (props.disabled ? Colors.lightGrey : Colors.black)};
  transition: all, 0.3s;
  text-align: center;
  width: 100%;
`;

export default TextOnly;
