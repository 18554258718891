import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import H5 from '../../text-elements/heading/H5';
import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow-right.svg';
import Devices from '../../../consts/Devices';
import Colors from '../../../consts/Colors';

const OnboardingCarouselNext = ({ onClick, children, className }) => {
  return (
    <Control className={className} onClick={() => onClick()}>
      <Heading>{children}</Heading>
      <Inner>
        <ArrowRight />
      </Inner>
    </Control>
  );
};

const Control = styled.div`
  cursor: pointer;
  min-width: 145px;
  position: relative;
  border-top-left-radius: 10px;
  padding: 1.5rem 2rem;
  background-color: ${Colors.white};
  transition: all, 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Inner = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${Colors.turquoise};
    width: 20px;
    height: 9px;
  }
`;

const Heading = styled(H5)`
  cursor: pointer;
  text-align: center;
  color: ${Colors.black};
  transition: all, 0.3s;
  text-align: center;
  width: 100%;
`;

export default OnboardingCarouselNext;
