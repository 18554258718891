import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Interweave from 'interweave';

import { ReactComponent as AudioIcon } from '../../assets/svg/audio-icon.svg';
import { ReactComponent as PlayIcon } from '../../assets/svg/play-icon.svg';

import H3 from '../text-elements/heading/H3';
import H4 from '../text-elements/heading/H4';
import B4 from '../text-elements/body/B4';
import Colors from '../../consts/Colors';
import Devices from '../../consts/Devices';
import Arrow from '../arrow/Arrow';

const Category = ({ category }) => {
  let [page, setPage] = useState(0);
  let items = category.items.slice(page * 3, page * 3 + 3);
  let totalPages = category.items.length / 3;

  useEffect(() => {
    items = category.items.slice(page * 3, page * 3 + 3);
    getImage();
  }, [page]);

  const getImage = item => {
    if (!item) {
      return;
    }

    if (item.category == 'Video') {
      if (item.featured_image_src) {
        return item.featured_image_src;
      }

      if (item.acf.youtube_link) {
        var r,
          rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        r = item.acf.youtube_link.match(rx);
        return `https://img.youtube.com/vi/${r[1]}/maxresdefault.jpg`;
      }
    } else {
      if (item.featured_image_src) {
        return item.featured_image_src;
      }
    }
  };

  return (
    <Wrapper>
      <TopBar>
        <CategoryTitle>{category.category}S</CategoryTitle>
        {category.items.length > 3 && (
          <Pagination>
            <ArrowComp
              dir="left"
              onClick={() => {
                if (page > 0) {
                  setPage(page - 1);
                }
              }}
            />
            <ArrowComp
              dir="right"
              onClick={() => {
                if (page < totalPages - 1) {
                  setPage(page + 1);
                }
              }}
            />
          </Pagination>
        )}
      </TopBar>
      <div>
        {items.map(item => {
          return (
            <Link to={`/info-library/${item.slug}`}>
              <Item>
                <Image
                  className="category-image-div"
                  style={{ backgroundImage: `url(${getImage(item)})` }}
                >
                  {category.category == 'podcast' && <AudioIcon></AudioIcon>}
                  {category.category == 'video' && <PlayIcon></PlayIcon>}
                </Image>
                <Inner>
                  <SubLine>
                    {moment(item.date).format('MMM Do YYYY')}{' '}
                    {item.acf.byline && `| ${item.acf.byline}`}
                  </SubLine>
                  <Title>
                    <Interweave content={item.title.rendered} />
                  </Title>
                </Inner>
              </Item>
            </Link>
          );
        })}
      </div>
    </Wrapper>
  );
};

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.75rem;
`;

const Pagination = styled.div`
  display: flex;

  > div {
    margin: 0 0.5rem;
    @media ${Devices.laptop} {
      &:hover {
        background: transparent;
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 2.75rem 1rem 0rem 1rem;
  max-width: 800px;
  margin: 0 auto;
  @media ${Devices.laptop} {
    padding: 2.75rem 0 0rem;
  }

  a {
    text-decoration: none;
    color: inherit;
    @media ${Devices.laptop} {
      width: 45%;
      width: calc(50% - 1rem);
    }
    > div {
      margin-bottom: 1rem;
    }
  }
  a:last-of-type {
    > div {
      margin-bottom: 0;
    }
  }

  > div {
    @media ${Devices.laptop} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

const CategoryTitle = styled(H3)``;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: ${Colors.boxShadow};

  @media ${Devices.laptop} {
    &:hover {
      .category-image-div {
        transition: opacity 0.2s ease-out;
        opacity: 0.5;
      }
    }
  }
`;

const Title = styled(H4)``;

const Inner = styled.div`
  background: ${Colors.white};
  display: flex;
  flex-direction: column;
  border: 3px solid #fff;
  flex: 4;
  padding: 2.375rem 1rem;
  justify-content: center;
`;

const Image = styled.div`
  flex: 1.5;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;

  @media ${Devices.laptop} {
    flex: 3;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SubLine = styled(B4)`
  margin-bottom: 0.33125rem;
  opacity: 0.5;
`;

const ArrowComp = styled(Arrow)`
  @media ${Devices.laptop} {
    &:hover {
      transition: all 0.2s ease-out;
      background: ${Colors.turquoise};
      svg {
        path {
          fill: ${Colors.white};
        }
      }
    }
  }
`;

export default Category;
