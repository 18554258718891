import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import B2 from '../text-elements/body/B2';
import Interweave from 'interweave';

const GdprText = ({}) => {
  const string = `The information contained in this app is for general information use only, does not constitute legal advice and should not be treated as a substitute for specific advice relevant to a particular circumstance.
    
  Brodies LLP does not accept responsibility for any loss which may arise from reliance on information published in this app. If you wish to find out more about the information included in this app, please contact a Brodies partner.
    For information on data that we collect through the app and why we are collecting it, please see our Privacy Policy. `;

  return (
    <Text>
      <Interweave content={string} />
    </Text>
  );
};

const Text = styled(B2)`
  font-family: Arial, Helvetica, sans-serif;

  p {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

export default GdprText;
