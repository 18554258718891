import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import StylePrefabs from '../../consts/StylePrefabs';
import AppActions from '../../actions/AppActions';
import HelpCentreActions from '../../actions/HelpCentreActions';
import HelpCentreSection from '../../components/help-centre/help-centre-section/HelpCentreSection';
import H1 from '../../components/text-elements/heading/H1';
import Devices from '../../consts/Devices';

class HelpCentre extends React.Component {
  componentDidMount() {
    this.props.setApp({
      showBlobs: true,
      showBackButton: true,
      backButton: {
        path: `/`,
        text: null
      }
    });
    this.props.loadHelpCentre();
  }

  render() {
    return (
      <>
        <Wrapper>
          <Title>FAQs</Title>
          {this.props.helpCentre.helpSections ? (
            <HelpCentreSections>
              {this.props.helpCentre.helpSections.map((section) => {
                return (
                  <HelpCentreSection title={section.title} questions={section.section_questions} />
                );
              })}
            </HelpCentreSections>
          ) : null}
        </Wrapper>
        <BGColourWrapper />
      </>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper}
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 4rem;
`;

const Title = styled(H1)`
  color: ${Colors.black};
  text-align: center;
  margin-bottom: 2rem;
  padding-left: 1rem;

  @media ${Devices.laptop} {
    text-align: left;
    padding-left: 0rem;
    margin-left: 0rem;
  }
`;

const HelpCentreSections = styled.div`
  padding: 2rem 1rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  @media ${Devices.laptop} {
    padding: 2rem 0rem;
  }
`;

const BGColourWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: -200;
  position: absolute;
  bottom: 0;
  background-color: ${Colors.white};
`;

const mapStateToProps = (state) => {
  return {
    helpCentre: state.helpCentre
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setApp: (data) => {
      dispatch(AppActions.setApp(data));
    },
    loadHelpCentre: () => {
      dispatch(HelpCentreActions.loadHelpCentre());
    }
  };
};

const connectedHelpCentreView = connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpCentre);

export default withRouter(connectedHelpCentreView);
