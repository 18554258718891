function stripUnit(val) {
  return val.replace(/[^-\d\.]/g, "");
}

const baseLineFontSizeCalc = (min, size, limit) => {
  let baseline = `375px`;
  let fontMultiplier = (size - min) / (stripUnit(limit) - stripUnit(baseline));
  let fontBaseline = min - fontMultiplier * stripUnit(baseline);

  return `
        font-size: ${min}px;
        line-height: 1.5;

        @media (min-width: ${baseline}) {
            font-size: ${min}px;
            font-size: calc(${fontMultiplier} * 100vw + ${fontBaseline}px);
        }
        
        @media (min-width: ${limit}) {
            font-size: ${size}px;
         
        }
    `;
};

const isRetina = (first, second) => {
  if (window.devicePixelRatio >= 2) {
    return second;
  }
  return first;
};

// const px2vw = (minSize, maxSize) => {
//   const minScreenSize = 375;
//   const maxScreenSize = 1200;

//   const fontSizeDifference = maxSize - minSize;
//   const screenSizeDifference = maxScreenSize - minScreenSize;

//   return `font-size: calc((${minSize} + ${fontSizeDifference}) * ((100vw - ${minScreenSize}px) / ${screenSizeDifference}));`;
// };

export default { baseLineFontSizeCalc, isRetina };
