import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../../consts/Colors';

const HelpCentreTextDivider = ({ visible, className }) => {
  return <Wrapper className={className} visible={visible} />;
};

const Wrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.visible ? '1px' : '0px')};
  background-color: ${Colors.lightGrey};
`;

export default HelpCentreTextDivider;
