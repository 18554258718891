import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

const ScrollView = ({ onScroll, children }) => {
  return (
    <Wrapper>
      <ScrollViewWrapper onScroll={onScroll}>{children}</ScrollViewWrapper>
      {/* <Gradient /> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  max-width: 500px;
`;

const ScrollViewWrapper = styled.div`
  /* height: 50vh; */
  /* overflow-y: scroll; */
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
`;

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 60px;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(244, 246, 248, 1));
  bottom: -5px;
`;

export default ScrollView;
