import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import H6 from '../text-elements/heading/H6';

const Share = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Inner>
        <H6>Share</H6>
        {/* <Arrow>
          <ArrowRight />
        </Arrow> */}
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  display: inline-block;
  cursor: pointer;

  h6 {
    color: ${Colors.black};
  }
`;

const Inner = styled.div`
  /* background: ${Colors.white};
  border-radius: 999em;
  padding: 7px 15px;
  display: flex;
  box-shadow: ${Colors.boxShadow}; */
  padding: 7px 15px;
`;

const Arrow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 9px;

  path {
    fill: ${Colors.turquoise};
  }
`;

export default Share;
