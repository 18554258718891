import React, { useRef, useState } from "react";
import styled from "styled-components/macro";

const SaveChecklistPrompt = (currentTitle, originalChecklistName) => {
  let checklistName;

  if (currentTitle === originalChecklistName) {
    checklistName = window.prompt("Would you like to give this checklist a name?", `${currentTitle}`);
    if (!checklistName) {
      checklistName = originalChecklistName;
    }
  } else {
    return currentTitle;
  }

  return checklistName;
};

export default SaveChecklistPrompt;
