import { LOAD_HELPCENTRE } from "./ActionTypes";
import axios from "axios";

const Actions = {
  loadHelpCentre: () => async (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/help_centre`).then((res) => {
      let rawData = res.data[0].acf["help-centre"];

      let parsedData = rawData.map((obj) => {
        return obj.help_section;
      });

      dispatch({
        type: LOAD_HELPCENTRE,
        payload: parsedData
      });
    });
  }
};

export default Actions;
