import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Typography from '../../consts/Typography';
import Devices from '../../consts/Devices';

const Post = ({ className, content }) => {
  return (
    <Wrapper className={className}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: 'Montserrat', sans-serif;

  h1 {
    font-size: ${Typography.fontSizes.mobile.h1};
    line-height: ${Typography.lineHeights.mobile.h1};

    @media ${Devices.mobileL} {
      font-size: ${Typography.fontSizes.desktop.h1};
      line-height: ${Typography.lineHeights.desktop.h1};
    }
  }

  h2 {
    font-size: ${Typography.fontSizes.mobile.h2};
    line-height: ${Typography.lineHeights.mobile.h2};

    @media ${Devices.mobileL} {
      font-size: ${Typography.fontSizes.desktop.h3};
      line-height: ${Typography.lineHeights.desktop.h3};
    }
  }

  p,
  li {
    font-size: ${Typography.fontSizes.mobile.b1};
    line-height: ${Typography.lineHeights.mobile.b1};

    @media ${Devices.mobileL} {
      font-size: ${Typography.fontSizes.desktop.b1};
      line-height: ${Typography.lineHeights.desktop.b1};
    }
  }

  h3 {
    font-size: ${Typography.fontSizes.mobile.h3};
    line-height: ${Typography.lineHeights.mobile.h3};

    @media ${Devices.mobileL} {
      font-size: ${Typography.fontSizes.desktop.h3};
      line-height: ${Typography.lineHeights.desktop.h3};
    }
  }

  li {
    margin-bottom: 1rem;
  }
`;

export default Post;
