import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Typography from '../../../consts/Typography';
import Devices from '../../../consts/Devices';
import Colors from '../../../consts/Colors';

const Error = ({ children, style, className }) => {
  return (
    <ErrorText style={style} className={className}>
      {children}
    </ErrorText>
  );
};

const ErrorText = styled.span`
  ${Typography.montserratRegular};

  text-align: center;

  color: ${Colors.red};
  font-size: ${Typography.fontSizes.desktop.b1};
  line-height: ${Typography.lineHeights.desktop.b1};

  @media ${Devices.mobileL} {
    font-size: ${Typography.fontSizes.mobile.b1};
    line-height: ${Typography.lineHeights.mobile.b1};
  }
`;

export default Error;
