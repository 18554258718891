import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Utils from '../../consts/Utils';
import Colors from '../../consts/Colors';
import AppActions from '../../actions/AppActions';
import NeuHeader from './neu-header';
import Toggle from './Toggle';
import Back from '../back/Back';
import Menu from './Menu';
import Call from './Call';
// import Blobs from "../blobs/Blobs";
import { useMediaQuery } from 'react-responsive';
import Devices from '../../consts/Devices';
import Logo from '../../assets/images/logo.png';
import Logo2x from '../../assets/images/logo@2x.png';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const HeaderComp = ({
  menuIsOpen,
  setApp,
  showBackButton,
  // showBlobs,
  history,
  backButton,
  hideMenu,
  withinOnboarding,
  platform,
  telephone
}) => {
  const isBigScreen = useMediaQuery({ query: `${Devices.laptop}` });
  const targetRef = useRef(null);
  const menuRef = useRef(null);
  const onLogoClick = () => {
    history.push('/');
  };

  const handleBackClick = () => {
    history.push(backButton.path);
  };

  useEffect(() => {
    return disableBodyScroll(targetRef.current);
  }, []);

  useEffect(() => {
    if (menuIsOpen) {
      disableBodyScroll(targetRef.current);
    } else {
      enableBodyScroll(targetRef.current);
    }
  }, [menuIsOpen]);

  return (
    <>
      <Header
        ref={targetRef}
        isOpen={menuIsOpen}
        logo={!isBigScreen ? <img src={Utils.isRetina(Logo, Logo2x)} /> : <></>}
        leftHand={
          showBackButton && !isBigScreen ? (
            <Back onClick={handleBackClick} />
          ) : showBackButton && isBigScreen && !withinOnboarding ? (
            <>
              <Back onClick={handleBackClick} text={backButton.text} />
              <Call
                platform={platform}
                phone={number => {
                  telephone(number, platform);
                }}
              />
            </>
          ) : showBackButton && isBigScreen && withinOnboarding ? (
            <>
              <Back onClick={handleBackClick} />
            </>
          ) : !hideMenu ? (
            <Call
              platform={platform}
              phone={number => {
                telephone(number, platform);
              }}
            />
          ) : null
        }
        onLogoClick={onLogoClick}
        toggle={
          !hideMenu ? (
            <Toggle
              isOpen={menuIsOpen}
              toggleOpen={() => {
                setApp({ menuIsOpen: !menuIsOpen });
              }}
            />
          ) : null
        }
      />
      {/* {showBlobs && !isBigScreen && <Blobs />} */}
      <MenuWrapper active={menuIsOpen}>
        <Menu
          telephone={number => {
            telephone(number, platform);
          }}
        />
      </MenuWrapper>
    </>
  );
};

const Header = styled(NeuHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  /* top: 0;
  left: 0;
  right: 0; */
  padding: 1rem;
  z-index: 999;
  background: ${props => (props.isOpen ? Colors.white : 'transparent')};
  min-height: 100px;
  img {
    max-width: 100%;
    width: 84px;
  }

  @media ${Devices.laptop} {
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    > div {
      margin-left: 15px;
    }
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  top: -200%;
  left: 0;
  right: 0;
  bottom: auto;
  z-index: 998;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, 0.3);

  > div > div {
    transform: translateY(100vh);
  }

  ${props =>
    props.active &&
    `
    top: 0;
    padding-top: 100px;
    bottom: 0;
    > div > div{
      transform: translateY(0);
    }
  `}
`;

const mapStateToProps = state => {
  return {
    menuIsOpen: state.app.menuIsOpen,
    showBackButton: state.app.showBackButton,
    backButton: state.app.backButton,
    showBlobs: state.app.showBlobs,
    hideMenu: state.app.hideMenu,
    platform: state.app.platform
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setApp: data => {
      dispatch(AppActions.setApp(data));
    },
    telephone: (number, platform) => {
      dispatch(AppActions.telephone(number, platform));
    }
  };
};

const connectedHeaderComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComp);

export default withRouter(connectedHeaderComp);
