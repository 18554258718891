import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import HelpCentreQuestion from '../help-centre-question/HelpCentreQuestion';
import H4 from '../../text-elements/heading/H4';
import HelpCentreTextDivider from '../help-centre-text-divider/HelpCentreTextDivider';

const HelpCentreSection = ({ title, questions }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {questions.map((question) => {
        return <HelpCentreQuestion question={question.question} answer={question.answer} />;
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 3rem;
`;

const Title = styled(H4)`
  margin-bottom: 1rem;
`;

export default HelpCentreSection;
