import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { B4 } from '../../text-elements/index';

const CarouselStageText = ({ stage, total }) => {
  return (
    <Wrapper>
      <Body>
        Step {stage} / {total}
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Body = styled(B4)`
  opacity: 0.5;
`;

export default CarouselStageText;
