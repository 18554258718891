import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Check } from '../../../assets/svg/check.svg';
import Colors from '../../../consts/Colors';

const CloseCircleButton = ({}) => {
  return (
    <Wrapper>
      <Check />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* background: ${Colors.white}; */
  /* box-shadow: ${Colors.boxShadow}; */
  width: 27px;
  height: 27px;
  border-radius: 9999em;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 15px;
    height: 10px;

    path {
      stroke: ${Colors.turquoise};
    }
  }
`;

export default CloseCircleButton;
