import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right.svg"
import { withRouter } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import Next from "../ctas/next/Next"
import H4 from "../text-elements/heading/H4"
import B3 from "../text-elements/body/B3"
import { H5 } from "../text-elements/index"
import Devices from "../../consts/Devices"

const Card = ({
  type,
  title,
  to,
  icon,
  history,
  subTitle,
  barColor,
  sectionsLength,
  hovered,
  index,
  onMouseOver,
  onMouseOut,
  svgIcon,
}) => {
  if (type == "LOADING") {
    return (
      <Wrapper type={"LOADING"}>
        <Skeleton height={"100%"} />
      </Wrapper>
    )
  }
  return (
    <Wrapper
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      type={type}
      onClick={() => {
        if (to) {
          history.push(to)
        }
      }}
    >
      {icon && (
        <Icon type={type}>
          <img src={icon} className="bg" />
          {svgIcon && svgIcon}
        </Icon>
      )}

      <Title type={type} barColor={barColor} className="title">
        <B3>{subTitle}</B3>
        <H4>{title}</H4>
        {type === "CHECKLIST" ? <span>{sectionsLength} Sections</span> : null}
      </Title>
      {hovered === index ? (
        <StartCta textColor={Colors.turquoise}>Start</StartCta>
      ) : (
        <Arrow type={type}>
          <ArrowRight />
        </Arrow>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  box-shadow: ${Colors.boxShadow};
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  @media ${Devices.laptop} {
    &:hover {
      background-color: ${Colors.backgroundGrey};
      transition: all 0.2s ease-out;
      box-shadow: none;

      span {
        color: ${Colors.black};
      }

      h4 {
        color: ${Colors.black};
      }

      div {
        &:before {
          background: ${Colors.black};
        }
      }
    }
  }

  h4 {
    color: #fff;
    line-height: 27px;
  }

  ${props =>
    props.type == "CHECKLIST" &&
    `
    padding: 0;
    background: ${Colors.cream};
    h4 {
      color: ${Colors.black};
    }
  `}

${props =>
  props.type == "HOME-FULL" &&
  `
    width: 100%!important;
    svg{
      top: 59%!important;
    }
  `}

  ${props =>
    props.type == "LOADING" &&
    `
    width: 100%!important;
    background: transparent;
    box-shadow: 0px 0px 0px;
    padding: 0;
    overflow: hidden;
    > span {
      height: 196px!important;
      margin-bottom: 10px;

      @media ${Devices.laptop} {
        height: 319px!important;
      }
      > span{
        border-radius: 10px!important;
      }
  `}
`

const Title = styled.div`
  h4 {
    color: ${Colors.black};
    text-align: center;
  }

  span {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    color: ${Colors.lightGrey};
  }
  ${props =>
    props.type == "CHECKLIST" &&
    `
    justify-content: center;
    display: flex;
    flex-direction: column;
      h4,span {
        text-align: left;
      }
      background-color: #fff;
      height: 100%;
      border-radius: 0 0 10px 10px;
      padding: 1rem;
    `};

  ${props =>
    props.barColor &&
    `
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        width: 8px;
        background: ${props.barColor};
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
      }
    `};
`

const StartCta = styled(Next)`
  pointer-events: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${Colors.white};

  svg {
    fill: ${Colors.turquoise};
  }
`

const CTAText = styled(H5)`
  color: ${Colors.turqoise} !important;
`

const Arrow = styled.div`
  background: ${Colors.white};
  box-shadow: ${Colors.boxShadow};
  width: 27px;
  height: 27px;
  border-radius: 9999em;
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 9px;
    height: 9px;
  }

  ${props =>
    props.type == "CHECKLIST" &&
    `
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: ${Colors.turquoise};
    svg path{
      fill: #fff;
    }
  `}
`

const Icon = styled.div`
  margin: ${props =>
    props.type === "CHECKLIST" ? `1rem auto` : `1.5rem auto`};
  width: 100%;
  justify-content: center;
  display: flex;
  img {
    max-height: 115px;
  }

  @media ${Devices.laptop} {
    margin: 4rem auto;
  }

  ${props =>
    props.type == "HOME" &&
    `
    .bg{
      animation:spin 30s linear infinite alternate;
    }
    @keyframes spin { 
      0% {  transform:rotate(0deg) scale(1) }
      45% {  transform:rotate(65deg) scale(1.3) }
      100% {  transform:rotate(180deg) scale(1.2)}
    }

    svg{
      position: absolute;
      top: 50%;
      transform: translateY(-100%);
    }

  `}

  ${props =>
    props.type == "HOME-FULL" &&
    `
    .bg{
      animation:spin 30s linear infinite alternate;
    }
    @keyframes spin { 
      0% {  transform:rotate(0deg) scale(1) }
      45% {  transform:rotate(65deg) scale(1.3) }
      100% {  transform:rotate(180deg) scale(1.2)}
    }

    svg{
      position: absolute;
      top: 50%;
      transform: translateY(-100%);
    }

  `}
`

export default withRouter(Card)
