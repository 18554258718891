import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import H5 from '../../text-elements/heading/H5';
import Colors from '../../../consts/Colors';
import Devices from '../../../consts/Devices';

const Contact = ({ children, icon }) => {
  return (
    <Wrapper>
      <Control>
        <Heading>{children}</Heading>
        <Inner>{icon}</Inner>
      </Control>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Control = styled.div`
  position: relative;
  border-radius: 15px;
  padding: 0px 17px;
  background-color: ${props => (props.disabled ? Colors.white : Colors.turquoise)};
  transition: all, 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #0000000d;
  min-width: 120px;

  @media ${Devices.laptop} {
    &:hover {
      background: ${Colors.white};
      svg {
        path {
          fill: ${Colors.turquoise};
        }
      }
      h5 {
        color: ${Colors.turquoise};
      }
    }
  }
`;

const Inner = styled.div`
  /* position: absolute; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0px !important;

  /* top: 50%;
  right: 10px;
  transform: translateY(-50%); */

  svg {
    fill: white;

    max-height: 20px;
  }
`;

const Heading = styled(H5)`
  color: ${props => (props.disabled ? Colors.lightGrey : Colors.white)};
  transition: all, 0.3s;
  width: 100%;
  padding: 8px 0;
  flex: 1;
  text-align: left !important;
  margin-right: 12px;
`;

export default Contact;
