import { LOADED_CATEGORY } from "../actions/ActionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADED_CATEGORY:
      const index = state.findIndex(
        item => item.category === action.payload.category
      );
      if (index > -1) {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            items: action.payload.items
          },
          ...state.slice(index + 1)
        ];
      }
      return [...state, action.payload];
    default:
      return state;
  }
}
