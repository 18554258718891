import { SET_APP, OPEN_SHARE_SHEET, OPEN_TEL_MODAL } from '../actions/ActionTypes';

const initialState = {
  loaded: true,
  checklists: [null, null, null],
  menuIsOpen: false,
  showBackButton: false,
  backButton: { path: null, text: null },
  showHeader: true,
  showBlobs: true,
  hideMenu: false,
  hydrated: false,
  shareUrl: null,
  showIosBanner: true,
  onboarded: false,
  withinOnboarding: false,
  platform: null,
  forwardingPath: null,
  telModalNumber: null
};

export default function(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_APP:
      return {
        ...state,
        ...action.payload
      };
    case OPEN_SHARE_SHEET:
      return {
        ...state,
        shareUrl: action.payload.url
      };
    case OPEN_TEL_MODAL:
      return {
        ...state,
        telModalNumber: action.payload
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        hydrated: true
      };

    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        menuIsOpen: false,
        shareUrl: null
      };
    default:
      return state;
  }
}
