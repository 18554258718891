import React, { useRef, useState } from 'react';
import AndroidOnboardingCarousel from './android-onboarding-carousel/AndroidOnboardingCarousel';
import DesktopOnboardingCarousel from './desktop-onboarding-carousel/DesktopOnboardingCarousel';
import IOSOnboardingCarousel from './ios-onboarding-carousel/IosOnboardingCarousel';

const OnboardingCarousel = ({ controls, platform }) => {
  return (
    <>
      {platform === 'Android' ? (
        <AndroidOnboardingCarousel />
      ) : platform === 'Desktop' ? (
        <DesktopOnboardingCarousel />
      ) : (
        <IOSOnboardingCarousel />
      )}
    </>
  );
};

export default OnboardingCarousel;
