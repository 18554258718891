import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Colors from '../../consts/Colors';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import { ReactComponent as Tick } from '../../assets/svg/tick.svg';
import { ReactComponent as Cross } from '../../assets/svg/cross.svg';
import Devices from '../../consts/Devices';
import { H5, B2 } from '../text-elements/index';

const AddToHomeScreenButton = ({ children, className }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [acceptedPrompt, setAcceptedPrompt] = useState(null);

  useEffect(() => {
    function handlePrompt(e) {
      e.preventDefault();

      setDeferredPrompt(e);
    }

    window.addEventListener('beforeinstallprompt', handlePrompt);

    return () => window.removeEventListener('beforeinstallprompt', handlePrompt);
  }, []);

  const buttonClicked = () => {
    console.log('Clicked');
    deferredPrompt.prompt();
    // console.log(`deferredPrompt: ${deferredPrompt}`);

    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        setAcceptedPrompt(true);
        // console.log('User accepted the A2HS prompt');
      } else {
        setAcceptedPrompt(false);
        // console.log('User dismissed the A2HS prompt');
      }
      setDeferredPrompt(null);
    });
  };

  console.log(acceptedPrompt);

  return (
    <>
      <>
        {acceptedPrompt === null ? (
          <Control className={className} onClick={() => buttonClicked()}>
            <Inner>
              <Heading>Add to Home Screen</Heading>
              <ArrowRight />
            </Inner>
          </Control>
        ) : acceptedPrompt === true ? (
          <ResultWrapper>
            <Tick />
            <B2>Success! From now on you can access Brodies via your applications folder</B2>
          </ResultWrapper>
        ) : acceptedPrompt === false ? (
          <ResultWrapper>
            <Cross />
            <B2>
              No problem. You can still do this manually yourself from within your browser options
              at a later date.
            </B2>
          </ResultWrapper>
        ) : null}
      </>
    </>
  );
};

const Control = styled.div`
  cursor: pointer;
  /* min-width: 145px; */
  /* position: relative; */
  border-radius: 9999em;
  padding: 0.3125rem 1rem;
  /* padding: 2rem 4rem; */
  background-color: ${Colors.turquoise};
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;

  @media ${Devices.laptop} {
    &:hover {
      background: ${Colors.white};
      svg {
        path {
          fill: ${Colors.turquoise};
          stroke: transparent;
        }
      }
      h5 {
        color: ${Colors.turquoise};
      }
    }
  }
`;

const Inner = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    transition: all 0.2s;
    fill: white;
    /* width: 25px;
    height: 25px; */
    width: 9px;
    height: 9px;
  }
`;

const ResultWrapper = styled.div`
  max-width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg {
    width: 30px;
    height: 30px;
    margin-bottom: 1rem;

    path {
      stroke: ${Colors.turquoise};
    }
  }
`;

const Heading = styled(H5)`
  cursor: pointer;
  text-align: center;
  margin-right: 0.75rem;
  color: ${Colors.white};
  transition: all, 0.3s;
  text-align: center;
`;

const Body = styled(B2)`
  text-align: center;
  max-width: 240px;
`;

export default AddToHomeScreenButton;
