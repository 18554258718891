import { LOAD_EXPERTS, LOAD_EXPERT, CLEAR_EXPERT } from './ActionTypes';

import axios from 'axios';

const Actions = {
  loadExperts: () => async dispatch => {
    dispatch({
      type: CLEAR_EXPERT
    });
    let experts = await axios.get(`${process.env.REACT_APP_API_URL}/expert`);

    dispatch({
      type: LOAD_EXPERTS,
      payload: experts.data
    });
  },
  loadExpert: slug => async dispatch => {
    dispatch({
      type: CLEAR_EXPERT
    });
    let experts = await axios.get(`${process.env.REACT_APP_API_URL}/expert?slug=${slug}`);

    dispatch({
      type: LOAD_EXPERT,
      payload: experts.data[0]
    });
  }
};

export default Actions;
