export const SET_APP = 'SET_APP';
export const OPEN_SHARE_SHEET = 'OPEN_SHARE_SHEET';
export const OPEN_TEL_MODAL = 'OPEN_TEL_MODAL';

export const SET_ONBOARDING_STATUS = 'SET_ONBOARDING_STATUS';
export const SET_CHECKLIST = 'SET_CHECKLIST';
export const SET_QUESTION_ANSWERED = 'SET_QUESTION_ANSWERED';
export const SET_SECTION_COMPLETED = 'SET_SECTION_COMPLETED';
export const SET_TOOLTIP_VIEWED = 'SET_TOOLTIP_VIEWED';

export const ADD_CHECKLIST = 'ADD_CHECKLIST';
export const REMOVE_CHECKLIST = 'REMOVE_CHECKLIST';
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';

export const LOADED_CATEGORY = 'LOADED_CATEGORY';
export const CLEAR_ARTICLE = 'CLEAR_ARTICLE';
export const LOADED_ARTICLE = 'LOADED_ARTICLE';

export const LOAD_HELPCENTRE = 'LOAD_HELPCENTRE';

export const LOAD_EXPERTS = 'LOAD_EXPERTS';
export const LOAD_EXPERT = 'LOAD_EXPERT';
export const CLEAR_EXPERT = 'CLEAR_EXPERT';
