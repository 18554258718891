export default {
  mobileS: '320px',
  mobileM: '375px',
  mobileTall: `775px`,
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};
