import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { B2 } from '../../text-elements/index';
import Interweave from 'interweave';

const CarouselBody = ({ className, children }) => {
  return (
    <BodyText className={className}>
      <Interweave content={ListParser(children)} />
    </BodyText>
  );
};

const ListParser = originalText => {
  let modifiedText, finalText;
  let bulletRegex = new RegExp(/\([A-Z]\)/gi);
  let match = originalText.match(bulletRegex);
  if (match) {
    let semiColonRegex = new RegExp(/;/gi);
    modifiedText = originalText.replace(bulletRegex, '<li>');
    modifiedText = modifiedText.replace(semiColonRegex, '</li>');
    let textArray = InsertFirstUl(modifiedText);
    textArray = InsertLastUl(textArray);
    finalText = textArray.join('');
  } else {
    finalText = originalText;
  }

  return finalText;
};

const InsertFirstUl = originalText => {
  let newText = originalText.split(/(?=<li>)/g);
  let liFound = 0;
  for (let textEl of newText) {
    if (textEl.includes('<li>') && liFound < 1) {
      let index = newText.indexOf(textEl);
      newText[index] = '<ul>' + textEl;
      liFound++;
    }
  }
  return newText;
};

const InsertLastUl = originalText => {
  let newText = originalText;

  let liIndex = 0;
  for (let textEl of newText) {
    if (textEl.includes('</li>')) {
      liIndex = newText.indexOf(textEl);
    }
  }
  newText[liIndex] = newText[liIndex] + '</ul>';

  return newText;
};

const BodyText = styled(B2)`
  min-width: calc(334px - 4rem);
  text-align: center;
  padding: 0rem 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;

  ul {
    margin-top: 10px;
    /* list-style-position: inside; */
    text-align: left;
    padding: 0px;
    margin: 0px;
    padding-left: 25px;
  }
`;

export default CarouselBody;
