import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';
import AppActions from '../../../actions/AppActions';
import NewsActions from '../../../actions/NewsActions';
import Colors from '../../../consts/Colors';
import H1 from '../../../components/text-elements/heading/H1';
import StylePrefabs from '../../../consts/StylePrefabs';
import Articles from '../../../components/articles/Articles';
import Category from '../../../components/category/Category';
import Devices from '../../../consts/Devices';

class InfoLibraryView extends React.Component {
  componentDidMount() {
    this.props.setApp({
      showBackButton: true,
      showBlobs: true,
      backButton: {
        path: `/`,
        text: null
      }
    });
    this.props.loadPosts();
  }
  render() {
    return (
      <Wrapper>
        <Title>Information Library</Title>
        <Inner>
          <Articles
            category={this.props.newsItems.filter(items => items.category == 'article')[0]}
          />

          {this.props.newsItems
            .filter(items => items.category !== 'article')
            .map(category => {
              if (category.items.length > 0) {
                return <Category key={category.name} category={category} />;
              } else {
                return <></>;
              }
            })}
        </Inner>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${StylePrefabs.pageWrapper};
  padding-bottom: 4rem;
  margin: 0 auto;
  @media ${Devices.laptop} {
    max-width: 1940px;
  }
  @media ${Devices.laptop} {
    .slick-slider {
      margin-left: -15px;
    }
  }
`;

const Inner = styled.div`
  border-radius: 20px 20px 0 0;
  /* background: ${Colors.cream}; */
  margin-top: -20px;
`;

const Title = styled(H1)`
  color: ${Colors.black};
  text-align: center;
  margin-bottom: 2rem;
  padding: 0rem 1rem 0rem;
  padding: 0rem;
  @media ${Devices.laptop} {
    padding: 0rem;
    max-width: 800px;
    text-align: left;
    margin: 0 auto 1.5rem;
  }
`;

const mapStateToProps = state => {
  return {
    newsItems: state.news
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setApp: data => {
      dispatch(AppActions.setApp(data));
    },
    loadPosts: () => {
      dispatch(NewsActions.loadPosts());
    }
  };
};

const connectedInfoLibraryView = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoLibraryView);

export default withRouter(connectedInfoLibraryView);
