import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import H2 from '../../text-elements/heading/H2';
import B2 from '../../text-elements/body/B2';
import Previous from '../../ctas/previous/Previous';

const CompletedChecklistModalComp = ({ checklistName, history }) => {
  return (
    <Wrapper>
      <Heading>Checklist complete!</Heading>
      <Body>
        You have successfully completed a {checklistName} checklist. You will now see this in the
        'Completed checklists' section
      </Body>
      <BackToChecklistsBtn
        onClick={() => {
          history.push('/checklists');
        }}
      >
        Back to checklists
      </BackToChecklistsBtn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled(H2)`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const Body = styled(B2)`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const BackToChecklistsBtn = styled(Previous)``;

export default CompletedChecklistModalComp;
