import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Devices from '../../consts/Devices';

const HeaderComp = ({ className, leftHand, logo, toggle, onLogoClick, ref }) => {
  return (
    <header className={className} ref={ref}>
      {leftHand}
      <LogoWrapper onClick={() => onLogoClick()}>
        <Link to="/">{logo}</Link>
      </LogoWrapper>
      {toggle}
    </header>
  );
};

const Header = styled.header`
  @media ${Devices.laptop} {
    width: 100%;
  }
`;

const LogoWrapper = styled.header`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export default HeaderComp;
