import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Utils from '../../consts/Utils';
import Colors from '../../consts/Colors';
import Devices from '../../consts/Devices';

import H6 from '../text-elements/heading/H6';

const Toggle = ({ isOpen, toggleOpen }) => {
  return (
    <Wrapper onClick={toggleOpen} active={isOpen}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25.5" height="21.5">
        <g data-name="Group 2" fill="none" stroke="#000" stroke-linecap="round" stroke-width="1.5">
          <path data-name="Line 3" d="M.75.75h24" />
          <path data-name="Line 4" d="M.75 10.75h24" />
          <path data-name="Line 5" d="M.75 20.75h24" />
        </g>
      </svg>
      <Title>
        <H6>MENU</H6>
      </Title>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 9px 10px 2px;
  border-radius: 5px;
  background: ${Colors.white};
  box-shadow: ${Colors.boxShadow};
  justify-content: center;
  cursor: pointer;
  svg {
    margin: 0 auto 10px;
    path {
      transition: all 0.3s;
    }
  }

  @media ${Devices.laptop} {
    display: none;
  }

  ${props =>
    props.active &&
    `
    svg{
        path:nth-child(2){
            opacity: 0;
        }
        path:nth-child(1){
            transform: translateY(10px) rotate(45deg);
            transform-origin: 13px 0px;
        }
        path:nth-child(3){
            transform: translateY(-10px) rotate(-45deg);
            transform-origin: 12px 21px;
        }
    }
  `}
`;

const Title = styled.div``;

export default Toggle;
