import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { useMediaQuery } from "react-responsive"
import Devices from "../../consts/Devices"
import Colors from "../../consts/Colors"
import Utils from "../../consts/Utils"
import H4 from "../text-elements/heading/H4"
import B1 from "../text-elements/body/B1"
import { NavLink, Link } from "react-router-dom"

import Logo from "../../assets/images/logo@2x.png"
import Logo2x from "../../assets/images/logo.png"

import Checklist from "../../assets/images/icon-checklist-full.png"
import Checklist2x from "../../assets/images/icon-checklist-full@2x.png"

import Experts from "../../assets/images/icon-experts-full.png"
import Experts2x from "../../assets/images/icon-experts-full@2x.png"

import Library from "../../assets/images/icon-library-full.png"
import Library2x from "../../assets/images/icon-library-full@2x.png"

import FAQS from "../../assets/images/icon-faqs-full.png"
import FAQS2x from "../../assets/images/icon-faqs-full@2x.png"

import Statement from "../../assets/images/icon-statement-full.png"
import Statement2x from "../../assets/images/icon-statement-full@2x.png"

const SidebarNav = ({ showBackButton, onBoarded, name }) => {
  const isBigScreen = useMediaQuery({ query: `${Devices.laptop}` })
  const isActive = to => (match, location) => location.pathname.includes(to)
  if (isBigScreen) {
    if (!onBoarded) {
      return <></>
    }
    return (
      <Wrapper showBackButton={showBackButton}>
        <NavBar>
          <LogoComp showBackButton={showBackButton}>
            <Link to={"/"}>
              <img src={Utils.isRetina(Logo, Logo2x)} />
            </Link>
          </LogoComp>
          <Links showBackButton={showBackButton}>
            <LinkWrapper showBackButton={showBackButton}>
              <NavLink
                to="/checklists"
                isActive={isActive("checklist")}
                activeClassName="active"
              >
                <Icon>
                  <img src={Utils.isRetina(Checklist, Checklist2x)} />
                </Icon>
                {!showBackButton && <H4>Checklists</H4>}
              </NavLink>
            </LinkWrapper>
            <LinkWrapper showBackButton={showBackButton}>
              <NavLink to="/experts" activeClassName="active">
                <Icon>
                  <img src={Utils.isRetina(Experts, Experts2x)} />
                </Icon>
                {!showBackButton && <H4>Contact An Expert</H4>}
              </NavLink>
            </LinkWrapper>
            <LinkWrapper showBackButton={showBackButton}>
              <NavLink to="/reader/statement" activeClassName="active">
                <Icon>
                  <img src={Utils.isRetina(Statement, Statement2x)} />
                </Icon>
                {!showBackButton && <H4>TAKING A STATEMENT</H4>}
              </NavLink>
            </LinkWrapper>
            <LinkWrapper showBackButton={showBackButton}>
              <NavLink to="/info-library" activeClassName="active">
                <Icon>
                  <img src={Utils.isRetina(Library, Library2x)} />
                </Icon>
                {!showBackButton && <H4>Info Library</H4>}
              </NavLink>
            </LinkWrapper>
            <LinkWrapper showBackButton={showBackButton}>
              <NavLink to="/help-centre" activeClassName="active">
                <Icon>
                  <img src={Utils.isRetina(FAQS, FAQS2x)} />
                </Icon>
                {!showBackButton && <H4>FAQs</H4>}
              </NavLink>
            </LinkWrapper>
          </Links>
          {!showBackButton && (
            <HelloWrapper>
              <Hello>
                <B1>Hello, {name && name.split(" ")[0]}</B1>
              </Hello>
              <a href="https://brodies.com/" target="_blank">
                Visit Brodies.com
              </a>
            </HelloWrapper>
          )}
        </NavBar>
      </Wrapper>
    )
  } else {
    return <></>
  }
}

const Wrapper = styled.div`
  width: ${props => (props.showBackButton ? "97px" : "360px")};
  transition: all 0.4s ease-out;
`

const NavBar = styled.div`
  position: fixed;
  top: 0;
  bottom: 1rem;
  left: 1rem;
  /* width: ${props => (props.showBackButton ? "200px" : "379px")}; */
  overflow: hidden;

  @media ${Devices.laptop} {
    height: 100vh;
    height: calc(100vh - 1em);
    overflow-y: scroll;
  }

  

  background: ${Colors.backgroundGrey};
  border-radius: 10px;

  a {
    display: block;
    padding: 1rem 0rem 1rem 0rem;
    color: inherit;
    display: flex;
    background: ${Colors.backgroundGrey};
    border-top: 1px solid rgba(0,0,0,0.05);
    &.active {
      background: ${Colors.white};
    }

    h4 {
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1rem;
    }
  }
`

const LogoComp = styled.div`
  margin: 0 auto 0 auto;
  background: ${Colors.white};
  text-align: center;

  padding: 2rem 0 2rem;

  a {
    padding: 0;
    border: 0;
    background: #fff;
    margin: 0 auto;
    display: block;
  }

  img {
    max-width: 100%;
    width: 50px;
    width: ${props => (props.showBackButton ? "60px" : "110px")};
    transition: all 0.4s ease-out;
  }

  @media ${Devices.laptopL} {
    padding: 3rem 0 3rem;

    img {
      width: ${props => (props.showBackButton ? "60px" : "130px")};
      transition: all 0.4s ease-out;
    }
  }
`

const Links = styled.div`
  /* padding: ${props =>
    props.showBackButton ? "0" : "0rem 3rem 0rem 1rem"}; */
  transition: all 0.4s;
  a div {
    padding: 0 1rem;
  }
`

const LinkWrapper = styled.div`
  padding: ${props => (props.showBackButton ? "0" : "0rem 3rem 0rem 1rem")};
  transition: background-color, 0.2s ease-out;

  a {
    transition: background-color, 0.2s ease-out;
  }

  @media ${Devices.laptop} {
    &:hover {
      background: ${Colors.white};
      transition: background-color, 0.2s ease-out;
      a {
        background: ${Colors.white};
        transition: background-color, 0.2s ease-out;
      }
    }
  }
`

const Icon = styled.div`
  img {
    width: 50px;
  }
`

const HelloWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 100%;
  span {
    color: #000;
  }

  a {
    color: #000000;
    display: block;
    text-align: center;
    border-width: 0px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    text-transform: uppercase;
  }

  @media (max-height: 800px) {
    position: relative;
    bottom: 20px;
    margin-top: 50px;
  }
`

const Hello = styled.div`
  background: rgba(0, 0, 0, 0.1);
  margin: 0;
  border-radius: 99999em;
  box-shadow: ${Colors.boxShadow};
  padding: 7px 20px;
  display: flex;
  display: inline-block;
  text-align: center;
  span {
    color: #000000;
  }
`

export default SidebarNav
